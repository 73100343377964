import { defineMessages } from 'react-intl';

export const scopeCommon = 'app.common';
export const scopeLogin = 'app.pages.Login';
export const scopeLabResult = 'app.pages.LabResults';
export const scopeLabSummary = 'app.pages.LabSummary';
export const scopeLabSummaryListView = 'app.pages.LabSummary';
export const scopeLabSummaryCompare = 'app.pages.LabSummaryCompare';
export const scopeLabConfigure = 'app.pages.LabConfigure';
export const scopeLabConfigureRange = 'app.pages.LabConfigureRange';
export const scopeHealthCarePlans = 'app.pages.HealthCarePlans';

export default defineMessages({
  success: {
    id: `${scopeCommon}.message.success`,
    defaultMessage: 'Success',
  },
  error: {
    id: `${scopeCommon}.message.error`,
    defaultMessage: 'Error',
  },
  tableTitleStatus: {
    id: `${scopeCommon}.table.title.status`,
    defaultMessage: 'Status',
  },
  tableTitleAction: {
    id: `${scopeCommon}.table.title.action`,
    defaultMessage: 'Action',
  },
  titleTotalResults: {
    id: `${scopeCommon}.title.total.results`,
    defaultMessage: 'Found 0 results',
  },
  buttonAdd: {
    id: `${scopeCommon}.button.add`,
    defaultMessage: 'Add',
  },
  buttonImport: {
    id: `${scopeCommon}.button.import`,
    defaultMessage: 'Import',
  },
  buttonExport: {
    id: `${scopeCommon}.button.export`,
    defaultMessage: 'Export',
  },
  buttonDownloadTemplate: {
    id: `${scopeCommon}.button.download.template`,
    defaultMessage: 'Download Template',
  },
  buttonPrint: {
    id: `${scopeCommon}.button.print`,
    defaultMessage: 'Print',
  },
  buttonUpdate: {
    id: `${scopeCommon}.button.update`,
    defaultMessage: 'Update',
  },
  buttonCancel: {
    id: `${scopeCommon}.button.cancel`,
    defaultMessage: 'Cancel',
  },
  buttonOk: {
    id: `${scopeCommon}.button.ok`,
    defaultMessage: 'OK',
  },
  buttonSubmit: {
    id: `${scopeCommon}.button.submit`,
    defaultMessage: 'Submit',
  },
  buttonSelectFile: {
    id: `${scopeCommon}.button.select.file`,
    defaultMessage: 'Select File',
  },
  tableTitleNo: {
    id: `${scopeCommon}.table.title.no`,
    defaultMessage: 'No.',
  },
  tableTitleFileName: {
    id: `${scopeCommon}.table.title.file.name`,
    defaultMessage: 'File Name',
  },
  popconfirmOkText: {
    id: `${scopeCommon}.popconfirm.okText`,
    defaultMessage: 'Yes',
  },
  popconfirmCancelText: {
    id: `${scopeCommon}.popconfirm.cancelText`,
    defaultMessage: 'No',
  },
  updateSuccess: {
    id: `${scopeCommon}.message.update.success`,
    defaultMessage: 'Update success',
  },
  messageDeleteSuccess: {
    id: `${scopeCommon}.message.delete.success`,
    defaultMessage: 'Delete success',
  },
  importSuccess: {
    id: `${scopeCommon}.message.import.success`,
    defaultMessage: 'Import success',
  },
  selectReady: {
    id: `${scopeCommon}.select.ready`,
    defaultMessage: 'Active',
  },
  selectNotReady: {
    id: `${scopeCommon}.select.not.ready`,
    defaultMessage: 'Not active',
  },
  tooltipSearch: {
    id: `${scopeCommon}.tooltip.search`,
    defaultMessage: 'Search',
  },
  tooltipReset: {
    id: `${scopeCommon}.tooltip.reset`,
    defaultMessage: 'Reset',
  },
  placeholderStartDate: {
    id: `${scopeCommon}.placeholder.start.date`,
    defaultMessage: 'Start date',
  },
  placeholderEndDate: {
    id: `${scopeCommon}.placeholder.end.date`,
    defaultMessage: 'End date',
  },
  placeholderStatusList: {
    id: `${scopeCommon}.placeholder.status.list`,
    defaultMessage: 'Status list',
  },
  tooltipLogout: {
    id: `${scopeCommon}.tooltip.logout`,
    defaultMessage: 'Logout',
  },
  languageJapanese: {
    id: `${scopeCommon}.language.japanese`,
    defaultMessage: 'Japanese',
  },
  languageEnglish: {
    id: `${scopeCommon}.language.english`,
    defaultMessage: 'English',
  },

  //
  titleLabResults: {
    id: `${scopeLabResult}.title.lab.results`,
    defaultMessage: 'LIST OF LAB RESULTS',
  },
  placeholderSearchByName: {
    id: `${scopeLabResult}.placeholder.search.by.name`,
    defaultMessage: 'Search by name',
  },
  placeholderConfigure: {
    id: `${scopeLabResult}.placeholder.configure`,
    defaultMessage: 'Configure',
  },
  tableTitleImportDate: {
    id: `${scopeLabResult}.table.title.import.date`,
    defaultMessage: 'Import Date',
  },
  tableStatusNew: {
    id: `${scopeLabResult}.table.status.new`,
    defaultMessage: 'NEW',
  },
  tableStatusProcessing: {
    id: `${scopeLabResult}.table.status.processing`,
    defaultMessage: 'PROCESSING',
  },
  tableStatusDone: {
    id: `${scopeLabResult}.table.status.done`,
    defaultMessage: 'DONE',
  },
  tableStatusError: {
    id: `${scopeLabResult}.table.status.error`,
    defaultMessage: 'ERROR',
  },
  modalTitleUpdateLabResult: {
    id: `${scopeLabResult}.modal.title.update.lab.result`,
    defaultMessage: 'Update Lab Result',
  },
  popconfirmTitleDeleteLabResult: {
    id: `${scopeLabResult}.popconfirm.title.delete.lab.result`,
    defaultMessage: 'Delete the lab result',
  },
  popconfirmDescriptionDeleteLabResult: {
    id: `${scopeLabResult}.popconfirm.description.delete.lab.result`,
    defaultMessage: 'Are you sure to delete this lab result?',
  },

  //
  placeholderUsername: {
    id: `${scopeLogin}.placeholder.username`,
    defaultMessage: 'Username',
  },
  placeholderPassword: {
    id: `${scopeLogin}.placeholder.password`,
    defaultMessage: 'Password',
  },
  buttonLogin: {
    id: `${scopeLogin}.button.login`,
    defaultMessage: 'Log in',
  },

  //
  titleLabResultDetail: {
    id: `${scopeLabSummary}.title.list.lab.results`,
    defaultMessage: 'List of Lab Results',
  },
  titleResultDetail: {
    id: `${scopeLabSummary}.title.result.details`,
    defaultMessage: 'Result Details',
  },
  segmentlabSummary: {
    id: `${scopeLabSummary}.segment.list.view`,
    defaultMessage: 'List View',
  },
  segmentListView: {
    id: `${scopeLabSummary}.segment.list.view`,
    defaultMessage: 'List View',
  },
  segmentSingleView: {
    id: `${scopeLabSummary}.segment.single.view`,
    defaultMessage: 'Single View',
  },
  selectNormal: {
    id: `${scopeLabSummary}.select.normal`,
    defaultMessage: '"Show normal values',
  },
  selectHigh: {
    id: `${scopeLabSummary}.select.high`,
    defaultMessage: 'Show high values',
  },
  selectLow: {
    id: `${scopeLabSummary}.select.low`,
    defaultMessage: 'Show low values',
  },
  checkboxHideColumns: {
    id: `${scopeLabSummaryListView}.checkbox.hide.columns`,
    defaultMessage: 'Hide columns empty value',
  },
  labelHighIndex: {
    id: `${scopeLabSummaryCompare}.label.high.index`,
    defaultMessage: 'High indexes',
  },
  labelLowIndex: {
    id: `${scopeLabSummaryCompare}.label.low.index`,
    defaultMessage: 'Low indexes',
  },
  titleDate: {
    id: `${scopeLabSummaryCompare}.title.date`,
    defaultMessage: 'Date',
  },
  titleSummary: {
    id: `${scopeLabSummaryCompare}.title.summary`,
    defaultMessage: 'Summary',
  },
  titleHealthCarePlan: {
    id: `${scopeLabSummaryCompare}.title.healthcare.plan`,
    defaultMessage: 'Healthcare Plan',
  },
  placeholderHealthCarePlan: {
    id: `${scopeLabSummaryCompare}.placeholder.healthcare.plan`,
    defaultMessage: 'Select pre-defined care plan',
  },

  //
  titleLabConfigures: {
    id: `${scopeLabConfigure}.title.lab.configures`,
    defaultMessage: 'LIST OF LAB CONFIGURES',
  },
  tableTitleName: {
    id: `${scopeLabConfigure}.table.title.name`,
    defaultMessage: 'Name',
  },
  tableTitleDescription: {
    id: `${scopeLabConfigure}.table.title.description`,
    defaultMessage: 'Description',
  },
  tableStatusActive: {
    id: `${scopeLabConfigure}.table.status.active`,
    defaultMessage: 'ACTIVE',
  },
  tableStatusNotActive: {
    id: `${scopeLabConfigure}.table.status.not.active`,
    defaultMessage: 'NOT ACTIVE',
  },
  modalTitleUpdateLabConfigure: {
    id: `${scopeLabConfigure}.modal.title.update.lab.configure`,
    defaultMessage: 'Update Lab Configure',
  },
  popconfirmTitleDeleteLabConfigure: {
    id: `${scopeLabConfigure}.popconfirm.title.delete.lab.configure`,
    defaultMessage: 'Delete the lab configure',
  },
  popconfirmDescriptionDeleteLabConfigure: {
    id: `${scopeLabConfigure}.popconfirm.description.delete.lab.configure`,
    defaultMessage: 'Are you sure to delete this lab configure?',
  },

  //
  titleLabConfigureRange: {
    id: `${scopeLabConfigureRange}.title.lab.configure.range`,
    defaultMessage: 'LAB CONFIGURE RANGE',
  },
  tableTitleIndexName: {
    id: `${scopeLabConfigureRange}.table.title.index.name`,
    defaultMessage: 'Index Name',
  },
  tableTitleDisplay: {
    id: `${scopeLabConfigureRange}.table.title.display`,
    defaultMessage: 'Display',
  },
  tableTitleStandardRange: {
    id: `${scopeLabConfigureRange}.table.title.standard.range`,
    defaultMessage: 'Standard Range',
  },
  tableTitleResult: {
    id: `${scopeLabConfigureRange}.table.title.result`,
    defaultMessage: 'Result',
  },
  tableTitleUnit: {
    id: `${scopeLabConfigureRange}.table.title.unit`,
    defaultMessage: 'Unit',
  },
  formLabelCode: {
    id: `${scopeLabConfigureRange}.form.label.code`,
    defaultMessage: 'Code',
  },
  formLabelName: {
    id: `${scopeLabConfigureRange}.form.label.name`,
    defaultMessage: 'Name',
  },
  formLabelDisplay: {
    id: `${scopeLabConfigureRange}.form.label.display`,
    defaultMessage: 'Display',
  },
  formLabelDescription: {
    id: `${scopeLabConfigureRange}.form.label.description`,
    defaultMessage: 'Description',
  },
  formLabelMaxValue: {
    id: `${scopeLabConfigureRange}.form.label.max.value`,
    defaultMessage: 'Max value',
  },
  formLabelMinValue: {
    id: `${scopeLabConfigureRange}.form.label.min.value`,
    defaultMessage: 'Min value',
  },
  formLabelUnit: {
    id: `${scopeLabConfigureRange}.form.label.unit`,
    defaultMessage: 'Unit',
  },
  modalTitleCreateConfigureRange: {
    id: `${scopeLabConfigureRange}.modal.title.create.configure.range`,
    defaultMessage: 'Create Lab Configure Range',
  },
  modalTitleUpdateConfigureRange: {
    id: `${scopeLabConfigureRange}.modal.title.update.configure.range`,
    defaultMessage: 'Update Lab Configure Range',
  },
  popconfirmTitleDeleteConfigureRange: {
    id: `${scopeLabConfigureRange}.popconfirm.title.delete.configure.range`,
    defaultMessage: 'Delete the lab configure range',
  },
  popconfirmDescriptionDeleteConfigureRange: {
    id: `${scopeLabConfigureRange}.popconfirm.description.delete.configure.range`,
    defaultMessage: 'Are you sure to delete this lab configure range?',
  },

  //
  titleHealthcarePlanTemplate: {
    id: `${scopeHealthCarePlans}.title.healthcare.plan.template`,
    defaultMessage: 'HEALTHCARE PLAN TEMPLATE',
  },
  tableTitleHealthcarePlanName: {
    id: `${scopeHealthCarePlans}.table.title.healthcare.plan.name`,
    defaultMessage: 'Healthcare Plan Name',
  },
  buttonAddNewTemplate: {
    id: `${scopeHealthCarePlans}.button.add.new.template`,
    defaultMessage: 'Add new template',
  },
  formLabelTemplateName: {
    id: `${scopeHealthCarePlans}.form.label.template.name`,
    defaultMessage: 'Template Name',
  },
  formLabelHealthcarePlanDetails: {
    id: `${scopeHealthCarePlans}.form.label.healthcare.plan.details`,
    defaultMessage: 'Healthcare Plan Details',
  },
  modalTitleUpdateHealthcarePlanTemplate: {
    id: `${scopeHealthCarePlans}.modal.title.update.healthcare.plan.template`,
    defaultMessage: 'Update Healthcare Plan Template',
  },
  popconfirmTitleDeleteHealthcarePlanTemplate: {
    id: `${scopeHealthCarePlans}.popconfirm.title.delete.healthcare.plan.template`,
    defaultMessage: 'Delete the healthcare plan template',
  },
  popconfirmDescriptionDeleteHealthcarePlanTemplate: {
    id: `${scopeHealthCarePlans}.popconfirm.description.delete.healthcare.plan.template`,
    defaultMessage: 'Are you sure to delete this healthcare plan template?',
  },
});
