import messages from '../locales/messages';

interface LabConfigureStatus {
  value: string;
  label: string;
}

export const LAB_CONFIGURE_STATUS: Record<string, LabConfigureStatus> = {
  ACTIVE: {
    value: 'ACTIVE',
    label: messages.tableStatusActive.id,
  },
  NOT_ACTIVE: {
    value: 'NOT_ACTIVE',
    label: messages.tableStatusNotActive.id,
  },
};
