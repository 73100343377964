/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store/store';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/apiConfig';
import { axiosInstance } from '../config/axiosConfig';

interface HealthCarePlanState {
  healthCarePlanData: any;
  createHealthCarePlanData: any;
  updateHealthCarePlanData: any;
  deleteHealthCarePlanData: any;
  loading: boolean;
  error: string | null;
}

const initialState: HealthCarePlanState = {
  healthCarePlanData: null,
  createHealthCarePlanData: null,
  updateHealthCarePlanData: null,
  deleteHealthCarePlanData: null,
  loading: false,
  error: null,
};

export const healthCarePlanSlice = createSlice({
  name: 'healthCarePlan',
  initialState,
  reducers: {
    healthCarePlanStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    healthCarePlanSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.healthCarePlanData = action.payload;
    },
    healthCarePlanFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    createHealthCarePlanStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    createHealthCarePlanSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.createHealthCarePlanData = action.payload;
    },
    createHealthCarePlanFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateHealthCarePlanStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateHealthCarePlanSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updateHealthCarePlanData = action.payload;
    },
    updateHealthCarePlanFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteHealthCarePlanStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteHealthCarePlanSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.deleteHealthCarePlanData = action.payload;
    },
    deleteHealthCarePlanFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    healthCarePlanCleanData: (state) => {
      state.healthCarePlanData = null;
      state.createHealthCarePlanData = null;
      state.updateHealthCarePlanData = null;
      state.deleteHealthCarePlanData = null;
      state.error = null;
    },
  },
});

export const {
  healthCarePlanStart,
  healthCarePlanSuccess,
  healthCarePlanFailure,
  createHealthCarePlanStart,
  createHealthCarePlanSuccess,
  createHealthCarePlanFailure,
  updateHealthCarePlanStart,
  updateHealthCarePlanSuccess,
  updateHealthCarePlanFailure,
  deleteHealthCarePlanStart,
  deleteHealthCarePlanSuccess,
  deleteHealthCarePlanFailure,
  healthCarePlanCleanData,
} = healthCarePlanSlice.actions;

export const fetchHealthCarePlanData =
  (signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(healthCarePlanStart());
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.FETCH_HEALTH_CARE_PLAN}`,
        {
          signal,
        },
      );
      dispatch(healthCarePlanSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          healthCarePlanFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const createHealthCarePlan =
  (
    createHealthCarePlanQuery: {
      name: string;
      detail: string;
    },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(createHealthCarePlanStart());
    try {
      const response = await axiosInstance.post(
        `${API_ENDPOINTS.CREATE_HEALTH_CARE_PLAN}`,
        createHealthCarePlanQuery,
        {
          signal,
        },
      );
      dispatch(createHealthCarePlanSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          createHealthCarePlanFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const updateHealthCarePlan =
  (
    id: number,
    updateHealthCarePlanQuery: {
      name: string;
      detail: string;
    },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(updateHealthCarePlanStart());
    try {
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.UPDATE_HEALTH_CARE_PLAN}/${id}`,
        updateHealthCarePlanQuery,
        {
          signal,
        },
      );
      dispatch(updateHealthCarePlanSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          updateHealthCarePlanFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const deleteHealthCarePlan =
  (id: number | undefined, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(deleteHealthCarePlanStart());
    try {
      const response = await axiosInstance.delete(
        `${API_ENDPOINTS.DELETE_HEALTH_CARE_PLAN}/${id}`,
        {
          signal,
        },
      );
      dispatch(deleteHealthCarePlanSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          deleteHealthCarePlanFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export default healthCarePlanSlice.reducer;
