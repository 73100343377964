import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Header from './components/Header';
import { IntlProvider } from 'react-intl';
import styled from 'styled-components';
import jpMessages from './locales/jp.json';
import enMessages from './locales/en.json';
import AppRoutes from './AppRoutes';

const messages = {
  en: enMessages,
  jp: jpMessages,
};

const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  padding: 0 16px;
  flex-direction: column;
  background-color: #e6f4ff;
`;

const App: React.FC = () => {
  const initialBasicAuth = sessionStorage.getItem('basicAuth') || '';
  const initialLocale = sessionStorage.getItem('locale') || 'jp';
  const [locale, setLocale] = useState(initialLocale);
  const [basicAuth, setBasicAuth] = useState(initialBasicAuth);

  useEffect(() => {
    sessionStorage.setItem('locale', locale);
  }, [locale]);

  useEffect(() => {
    sessionStorage.setItem('basicAuth', basicAuth);
  }, [basicAuth]);

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale as keyof typeof messages]}
    >
      <AppWrapper className={basicAuth ? '' : 'login'}>
        {basicAuth ? (
          <Row style={{ paddingTop: '15px', minHeight: '100vh' }} gutter={16}>
            <Col
              span={1}
              style={{ paddingTop: '5px', borderRight: '1px solid #d9d9d9' }}
            >
              <Header setBasicAuth={setBasicAuth} />
            </Col>
            <Col span={23}>
              <AppRoutes basicAuth={basicAuth} />
            </Col>
          </Row>
        ) : (
          <AppRoutes
            basicAuth=""
            setLocale={setLocale}
            setBasicAuth={setBasicAuth}
          />
        )}
      </AppWrapper>
    </IntlProvider>
  );
};

export default App;
