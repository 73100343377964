/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  // Select,
  Space,
  Spin,
  Table,
  // Tooltip,
  Typography,
  Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../locales/messages';
import {
  // Controller,
  useForm,
} from 'react-hook-form';
import { Section } from '../styles/commonStyles';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { isEmpty } from 'lodash';
import {
  deleteLabConfigure,
  exportLabConfigure,
  fetchLabConfigureData,
  importLabConfigure,
  labConfigureCleanData,
  updateLabConfigure,
} from '../reducers/labConfigureSlice';
import { useNavigate } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  // SearchOutlined,
  // UndoOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { LabConfigure } from '../common/interfaces';
import { LAB_CONFIGURE_STATUS } from '../contants/labConfigureStatus';

const LabConfigures: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    labConfigureData,
    updateLabConfigureData,
    deleteLabConfigureData,
    importLabConfigureData,
    loading,
    error,
  } = useSelector((state: RootState) => state.labConfigure);
  const intl = useIntl();
  const { TextArea } = Input;
  const navigate = useNavigate();
  const controller = new AbortController();
  const {
    handleSubmit,
    // control,
    // watch,
    // reset,
  } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalImportOpen, setIsModalImportOpen] = useState(false);
  const [nameVal, setNameVal] = useState('');
  const [descriptionVal, setDescriptionVal] = useState('');
  const [labResultId, setLabResultId] = useState<number | undefined>();
  const [fileList, setFileList] = useState<any[]>([]);

  // const status = watch('status') || '';

  const updateLabResultQuery = {
    name: nameVal,
    description: descriptionVal,
  };

  // Update lab configure
  const showModal = (item: LabConfigure) => {
    setNameVal(item.name);
    setDescriptionVal(item.description);
    setLabResultId(item.id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      updateLabConfigure(labResultId, updateLabResultQuery, controller.signal),
    );
    setIsModalOpen(false);
    setNameVal('');
    setDescriptionVal('');
    setLabResultId(undefined);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setNameVal('');
    setDescriptionVal('');
  };

  // Import lab configure
  const showModalImport = () => {
    setIsModalImportOpen(true);
  };

  const handleOkImport = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file);
    });
    if (!isEmpty(fileList)) {
      dispatch(importLabConfigure(formData, controller.signal));
      setIsModalImportOpen(false);
    }
    setIsModalImportOpen(false);
    setFileList([]);
  };

  const handleCancelImport = () => {
    setIsModalImportOpen(false);
    setFileList([]);
  };

  // Lab configure file props
  const fileProps = {
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const onSubmit = async () => {
    try {
      await dispatch(fetchLabConfigureData('ACTIVE', controller.signal));
    } catch (error: any) {
      console.error(
        'Error fetching lab results:',
        error.message || 'An unknown error occurred.',
      );
    }
  };

  // const onReset = async () => {
  //   try {
  //     reset();
  //     await dispatch(fetchLabConfigureData('', controller.signal));
  //   } catch (error: any) {
  //     console.error(
  //       'Error fetching lab results:',
  //       error.message || 'An unknown error occurred.',
  //     );
  //   }
  // };

  const deleteResult = (id: number) => {
    dispatch(deleteLabConfigure(id, controller.signal));
  };

  // Redirect to lab configure range
  const redirectToLabConfigureRange = (item: LabConfigure) => {
    navigate('/lab-configure-range', {
      state: {
        labConfigureId: item.id,
      },
    });
  };

  // Add index to lab configure data
  const labConfigureDataMap =
    (labConfigureData &&
      !isEmpty(labConfigureData) &&
      labConfigureData.map((i: LabConfigure, index: number) => ({
        ...i,
        index: index + 1,
      }))) ||
    [];

  const columns = [
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleNo.id}`,
      }),
      dataIndex: 'index',
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleName.id}`,
      }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleDescription.id}`,
      }),
      dataIndex: 'description',
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleStatus.id}`,
      }),
      render: (item: LabConfigure) =>
        intl.formatMessage({
          id: `${LAB_CONFIGURE_STATUS[item.status].label}`,
        }),
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleAction.id}`,
      }),
      render: (item: LabConfigure) => (
        <Space>
          <EyeOutlined
            style={{ color: 'rgba(0, 0, 0, 0.88)' }}
            onClick={() => redirectToLabConfigureRange(item)}
          />
          <EditOutlined onClick={() => showModal(item)} />
          <Modal
            title={intl.formatMessage({
              id: `${messages.modalTitleUpdateLabResult.id}`,
            })}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelText={intl.formatMessage({
              id: `${messages.buttonCancel.id}`,
            })}
            okText={intl.formatMessage({
              id: `${messages.buttonOk.id}`,
            })}
          >
            <Input
              style={{ marginBottom: '10px' }}
              placeholder={intl.formatMessage({
                id: `${messages.tableTitleName.id}`,
              })}
              value={nameVal}
              onChange={(e) => setNameVal(e.target.value)}
            />
            <TextArea
              rows={4}
              style={{ width: '100%' }}
              value={descriptionVal}
              onChange={(e) => setDescriptionVal(e.target.value)}
            />
          </Modal>
          <Popconfirm
            title={intl.formatMessage({
              id: `${messages.popconfirmTitleDeleteLabResult.id}`,
            })}
            description={intl.formatMessage({
              id: `${messages.popconfirmDescriptionDeleteLabResult.id}`,
            })}
            onConfirm={() => deleteResult(item.id)}
            okText={intl.formatMessage({
              id: `${messages.popconfirmOkText.id}`,
            })}
            cancelText={intl.formatMessage({
              id: `${messages.popconfirmCancelText.id}`,
            })}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (
      updateLabConfigureData?.message === 'success' ||
      deleteLabConfigureData?.message === 'success' ||
      importLabConfigureData?.message === 'success'
    ) {
      dispatch(fetchLabConfigureData('ACTIVE', controller.signal));
      message.success(
        intl.formatMessage({
          id: `${messages.success.id}`,
        }),
      );
    }
    if (error) {
      message.error(
        intl.formatMessage({
          id: `${messages.error.id}`,
        }),
      );
    }
  }, [
    updateLabConfigureData,
    deleteLabConfigureData,
    importLabConfigureData,
    error,
  ]);

  useEffect(() => {
    dispatch(fetchLabConfigureData('ACTIVE', controller.signal));
    return () => {
      dispatch(labConfigureCleanData());
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 style={{ margin: 0 }}>
          <FormattedMessage {...messages.titleLabConfigures} />
        </h2>
        <Typography style={{ marginBottom: '20px' }}>
          {intl.formatMessage(
            {
              id: `${messages.titleTotalResults.id}`,
            },
            {
              total: labConfigureDataMap && labConfigureDataMap.length,
            },
          )}
        </Typography>
        <Row justify="end">
          {/* <Col>
            <Space>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <Select
                    {...field}
                    style={{
                      width: 100,
                    }}
                    allowClear
                    options={[
                      {
                        value: 'ACTIVE',
                        label: intl.formatMessage({
                          id: `${messages.selectReady.id}`,
                        }),
                      },
                      {
                        value: 'NOT_ACTIVE',
                        label: intl.formatMessage({
                          id: `${messages.selectNotReady.id}`,
                        }),
                      },
                    ]}
                    placeholder={intl.formatMessage({
                      id: `${messages.tableTitleStatus.id}`,
                    })}
                  />
                )}
              />
              <Tooltip
                title={intl.formatMessage({
                  id: `${messages.tooltipSearch.id}`,
                })}
              >
                <Button
                  shape="circle"
                  icon={<SearchOutlined />}
                  onClick={handleSubmit(onSubmit)}
                />
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: `${messages.tooltipReset.id}`,
                })}
              >
                <Button
                  shape="circle"
                  icon={<UndoOutlined />}
                  onClick={onReset}
                />
              </Tooltip>
            </Space>
          </Col> */}
          <Col>
            <Space>
              <Button type="primary" onClick={() => showModalImport()}>
                <FormattedMessage {...messages.buttonImport} />
              </Button>
              <Modal
                title={intl.formatMessage({
                  id: `${messages.buttonImport.id}`,
                })}
                open={isModalImportOpen}
                onOk={handleOkImport}
                onCancel={handleCancelImport}
                cancelText={intl.formatMessage({
                  id: `${messages.buttonCancel.id}`,
                })}
                okText={intl.formatMessage({
                  id: `${messages.buttonOk.id}`,
                })}
                okButtonProps={{ disabled: isEmpty(fileList) }}
              >
                <Upload {...fileProps}>
                  <Button icon={<UploadOutlined />}>
                    <FormattedMessage {...messages.buttonSelectFile} />
                  </Button>
                </Upload>
              </Modal>
              <Button
                type="primary"
                onClick={() =>
                  dispatch(
                    exportLabConfigure('Lab_configures.csv', controller.signal),
                  )
                }
              >
                <FormattedMessage {...messages.buttonDownloadTemplate} />
              </Button>
            </Space>
          </Col>
        </Row>
        <input type="submit" hidden />
      </form>
      <Section>
        <Table columns={columns} dataSource={labConfigureDataMap} />
      </Section>
      <Spin fullscreen spinning={loading} />
    </>
  );
};

export default LabConfigures;
