/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store/store';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/apiConfig';
import { axiosFormDataInstance, axiosInstance } from '../config/axiosConfig';
import download from 'js-file-download';

interface LabConfigureState {
  labConfigureData: any;
  updateLabConfigureData: any;
  deleteLabConfigureData: any;
  importLabConfigureData: any;
  exportLabConfigureData: any;
  loading: boolean;
  error: string | null;
}

const initialState: LabConfigureState = {
  labConfigureData: null,
  updateLabConfigureData: null,
  deleteLabConfigureData: null,
  importLabConfigureData: null,
  exportLabConfigureData: null,
  loading: false,
  error: null,
};

export const labConfigureSlice = createSlice({
  name: 'labConfigure',
  initialState,
  reducers: {
    labConfigureStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    labConfigureSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.labConfigureData = action.payload;
    },
    labConfigureFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateLabConfigureStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateLabConfigureSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updateLabConfigureData = action.payload;
    },
    updateLabConfigureFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteLabConfigureStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteLabConfigureSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.deleteLabConfigureData = action.payload;
    },
    deleteLabConfigureFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    importLabConfigureStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    importLabConfigureSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.importLabConfigureData = action.payload;
    },
    importLabConfigureFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    exportLabConfigureStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    exportLabConfigureSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.exportLabConfigureData = action.payload;
    },
    exportLabConfigureFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    labConfigureCleanData: (state) => {
      state.labConfigureData = null;
      state.updateLabConfigureData = null;
      state.deleteLabConfigureData = null;
      state.importLabConfigureData = null;
      state.exportLabConfigureData = null;
      state.error = null;
    },
  },
});

export const {
  labConfigureStart,
  labConfigureSuccess,
  labConfigureFailure,
  updateLabConfigureStart,
  updateLabConfigureSuccess,
  updateLabConfigureFailure,
  deleteLabConfigureStart,
  deleteLabConfigureSuccess,
  deleteLabConfigureFailure,
  importLabConfigureStart,
  importLabConfigureSuccess,
  importLabConfigureFailure,
  exportLabConfigureStart,
  exportLabConfigureSuccess,
  exportLabConfigureFailure,
  labConfigureCleanData,
} = labConfigureSlice.actions;

export const fetchLabConfigureData =
  (status: string, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(labConfigureStart());
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.FETCH_LAB_CONFIGURE}?status=${status}`,
        {
          signal,
        },
      );
      dispatch(labConfigureSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(labConfigureFailure(error.message || 'Something went wrong'));
      }
    }
  };

export const updateLabConfigure =
  (
    id: number | undefined,
    updateLabConfigureQuery: {
      name: string;
      description: string;
    },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(updateLabConfigureStart());
    try {
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.UPDATE_LAB_CONFIGURE}/${id}`,
        updateLabConfigureQuery,
        {
          signal,
        },
      );
      dispatch(updateLabConfigureSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          updateLabConfigureFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const deleteLabConfigure =
  (id: number | undefined, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(deleteLabConfigureStart());
    try {
      const response = await axiosInstance.delete(
        `${API_ENDPOINTS.DELETE_LAB_CONFIGURE}/${id}`,
        {
          signal,
        },
      );
      dispatch(deleteLabConfigureSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          deleteLabConfigureFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const importLabConfigure =
  (formData: FormData, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(importLabConfigureStart());
    try {
      const response = await axiosFormDataInstance.post(
        `${API_ENDPOINTS.IMPORT_LAB_CONFIGURE}`,
        formData,
        {
          signal,
        },
      );
      dispatch(importLabConfigureSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          importLabConfigureFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const exportLabConfigure =
  (fileName: string, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(exportLabConfigureStart());
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.EXPORT_LAB_CONFIGURE}`,
        {
          signal,
          responseType: 'blob',
        },
      );
      download(response.data, fileName);
      dispatch(exportLabConfigureSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          exportLabConfigureFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export default labConfigureSlice.reducer;
