import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import messages from '../locales/messages';
import {
  Button,
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import { isEmpty } from 'lodash';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  createHealthCarePlan,
  deleteHealthCarePlan,
  fetchHealthCarePlanData,
  healthCarePlanCleanData,
  updateHealthCarePlan,
} from '../reducers/healthCarePlanSlice';
import { HealthCarePlan } from '../common/interfaces';

const HealthCarePlans: React.FC = () => {
  const {
    healthCarePlanData,
    createHealthCarePlanData,
    updateHealthCarePlanData,
    deleteHealthCarePlanData,
    loading,
    error,
  } = useSelector((state: RootState) => state.healthCarePlan);
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const { TextArea } = Input;
  const { Title } = Typography;
  const controller = new AbortController();
  const { handleSubmit, control, watch, reset, setValue } = useForm();
  const [healthcarePlanTemplateId, setHealthcarePlanTemplateId] = useState<
    number | undefined
  >();

  const name = watch('name') || '';
  const detail = watch('detail') || '';

  const healthcarePlanTemplateQuery = {
    name,
    detail,
  };

  const addNewHealcarePlanTemplate = () => {
    reset();
    setHealthcarePlanTemplateId(undefined);
  };

  const handleDeleteHealcarePlanTemplate = (id: number) => {
    dispatch(deleteHealthCarePlan(id, controller.signal));
  };

  const showModalHealcarePlanTemplate = (item: HealthCarePlan) => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
      setHealthcarePlanTemplateId(item.id);
    } else {
      setHealthcarePlanTemplateId(undefined);
    }
  };

  const onSubmit = () => {
    if (healthcarePlanTemplateId) {
      dispatch(
        updateHealthCarePlan(
          healthcarePlanTemplateId,
          healthcarePlanTemplateQuery,
          controller.signal,
        ),
      );
    } else {
      dispatch(
        createHealthCarePlan(healthcarePlanTemplateQuery, controller.signal),
      );
    }
    reset();
    setHealthcarePlanTemplateId(undefined);
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleHealthcarePlanName.id}`,
      }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleAction.id}`,
      }),
      render: (item: HealthCarePlan) => (
        <Space>
          <EditOutlined onClick={() => showModalHealcarePlanTemplate(item)} />
          <Popconfirm
            title={intl.formatMessage({
              id: `${messages.popconfirmTitleDeleteHealthcarePlanTemplate.id}`,
            })}
            description={intl.formatMessage({
              id: `${
                messages.popconfirmDescriptionDeleteHealthcarePlanTemplate.id
              }`,
            })}
            onConfirm={() => handleDeleteHealcarePlanTemplate(item.id)}
            okText={intl.formatMessage({
              id: `${messages.popconfirmOkText.id}`,
            })}
            cancelText={intl.formatMessage({
              id: `${messages.popconfirmCancelText.id}`,
            })}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (
      createHealthCarePlanData?.message === 'success' ||
      updateHealthCarePlanData?.message === 'success' ||
      deleteHealthCarePlanData?.message === 'success'
    ) {
      dispatch(fetchHealthCarePlanData(controller.signal));
      message.success(
        intl.formatMessage({
          id: `${messages.success.id}`,
        }),
      );
    }
    if (error) {
      message.error(
        intl.formatMessage({
          id: `${messages.error.id}`,
        }),
      );
    }
  }, [
    createHealthCarePlanData,
    updateHealthCarePlanData,
    deleteHealthCarePlanData,
    error,
  ]);

  useEffect(() => {
    dispatch(fetchHealthCarePlanData(controller.signal));
    return () => {
      dispatch(healthCarePlanCleanData());
    };
  }, []);

  return (
    <>
      <h2 style={{ margin: 0 }}>
        <FormattedMessage {...messages.titleHealthcarePlanTemplate} />
      </h2>
      <Typography style={{ marginBottom: '20px' }}>
        {intl.formatMessage(
          {
            id: `${messages.titleTotalResults.id}`,
          },
          {
            total: !isEmpty(healthCarePlanData) && healthCarePlanData.length,
          },
        )}
      </Typography>
      <Typography.Paragraph>
        <Row gutter={16}>
          <Col span={12}>
            <Table
              columns={columns}
              dataSource={
                (!isEmpty(healthCarePlanData) && healthCarePlanData) || []
              }
              pagination={false}
            />
            <Button
              icon={<PlusCircleOutlined />}
              style={{ width: '100%', marginTop: '5px' }}
              onClick={() => addNewHealcarePlanTemplate()}
            >
              <FormattedMessage {...messages.buttonAddNewTemplate} />
            </Button>
          </Col>
          <Col span={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Typography.Paragraph>
                    <Title level={5} style={{ marginTop: 0 }}>
                      <FormattedMessage {...messages.formLabelTemplateName} />:
                    </Title>
                    <Input
                      required
                      {...field}
                      placeholder={intl.formatMessage({
                        id: `${messages.tableTitleHealthcarePlanName.id}`,
                      })}
                    />
                  </Typography.Paragraph>
                )}
              />
              <Controller
                control={control}
                name="detail"
                render={({ field }) => (
                  <Typography.Paragraph>
                    <Title level={5} style={{ marginTop: 0 }}>
                      <FormattedMessage
                        {...messages.formLabelHealthcarePlanDetails}
                      />
                      :
                    </Title>
                    <TextArea
                      required
                      {...field}
                      rows={4}
                      placeholder={intl.formatMessage({
                        id: `${messages.formLabelHealthcarePlanDetails.id}`,
                      })}
                    />
                  </Typography.Paragraph>
                )}
              />
              <Row justify="end">
                <Button type="primary" htmlType="submit">
                  <FormattedMessage {...messages.buttonSubmit} />
                </Button>
              </Row>
            </form>
          </Col>
        </Row>
      </Typography.Paragraph>
      <Spin fullscreen spinning={loading} />
    </>
  );
};

export default HealthCarePlans;
