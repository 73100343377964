import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LabResults from './pages/LabResults';
import LabSummary from './pages/LabSummary';
import LabSummaryCompare from './pages/LabSummaryCompare';
import LabConfigures from './pages/LabConfigures';
import LabConfigureRange from './pages/LabConfigureRange';
import HealthCarePlans from './pages/HealthCarePlans';
import NotFoundPage from './pages/NotFoundPage';
import Login from './pages/Login';

interface AppRoutesProps {
  basicAuth: string;
  setLocale?: (locale: string) => void;
  setBasicAuth?: (basicAuth: string) => void;
}

const AppRoutes: React.FC<AppRoutesProps> = ({
  basicAuth,
  setLocale,
  setBasicAuth,
}) => {
  return (
    <Routes>
      {basicAuth ? (
        <>
          <Route path="/" element={<LabResults />} />
          <Route path="/lab-results" element={<LabResults />} />
          <Route path="/lab-summary" element={<LabSummary />} />
          <Route path="/lab-summary-compare" element={<LabSummaryCompare />} />
          <Route path="/lab-configures" element={<LabConfigures />} />
          <Route path="/lab-configure-range" element={<LabConfigureRange />} />
          <Route path="/health-care-plans" element={<HealthCarePlans />} />
          <Route path="*" element={<NotFoundPage />} />
        </>
      ) : (
        <>
          <Route
            path="/"
            element={
              <Login setLocale={setLocale} setBasicAuth={setBasicAuth} />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
