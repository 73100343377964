/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  message,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../locales/messages';
import moment from 'moment';
import { MOMENT_FORMAT } from '../config/momentFormats';
import { HomeOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { Section } from '../styles/commonStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store/store';
import { RootState } from '../store/rootReducer';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  SearchOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import {
  fetchLabSummaryData,
  labSummaryCleanData,
} from '../reducers/labSummarySlice';
import { useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { createStyles } from 'antd-style';

const useStyle = createStyles(({ css }) => {
  return {
    customTable: css`
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        min-width: 111px;
      }
    `,
  };
});

interface LabResultItem {
  id: number;
  importDate: string;
  fileName: string;
}

const LabSummary: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { labSummaryData, loading, error } = useSelector(
    (state: RootState) => state.labSummary,
  );
  const { styles } = useStyle();
  const intl = useIntl();
  const navigate = useNavigate();
  const { Text } = Typography;
  const { RangePicker } = DatePicker;
  const controller = new AbortController();
  const { handleSubmit, control, watch, reset } = useForm();
  const location = useLocation();
  const labResultItem = location.state?.labResultItem as LabResultItem;
  const labResultItemId = labResultItem?.id;

  const statusListOptions = [
    {
      value: 'NORMAL',
      label: intl.formatMessage({
        id: `${messages.selectNormal.id}`,
      }),
    },
    {
      value: 'HIGH',
      label: intl.formatMessage({
        id: `${messages.selectHigh.id}`,
      }),
    },
    {
      value: 'LOW',
      label: intl.formatMessage({
        id: `${messages.selectLow.id}`,
      }),
    },
  ];

  // Redirect to lab summary compare
  const redirectToLabSummaryCompare = (item: any) => {
    navigate('/lab-summary-compare', {
      state: {
        labResultItem,
        labSummaryItem: item,
      },
    });
  };

  // Transform header lab summary data
  const columns = useMemo(() => {
    if (
      labSummaryData &&
      labSummaryData.summary &&
      labSummaryData.summary.header
    ) {
      const headerArray = labSummaryData.summary.header
        .split(',')
        .filter((item: any) => item);
      return [
        {
          title: intl.formatMessage({
            id: `${messages.titleDate.id}`,
          }),
          render: (item: any) => {
            return (
              <Text
                onClick={() => redirectToLabSummaryCompare(item)}
                style={{ color: 'rgb(22, 119, 255)', cursor: 'pointer' }}
              >
                {moment(item.resultDate).format(MOMENT_FORMAT.YEAR_MONTH_DAY)}
              </Text>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: `${messages.titleSummary.id}`,
          }),
          render: (item: any) => (
            <Text>
              {intl.formatMessage({
                id: `${messages.labelHighIndex.id}`,
              })}
              : {item?.highIndication || '-'}
              <br />
              {intl.formatMessage({
                id: `${messages.labelLowIndex.id}`,
              })}
              : {item?.lowIndication || '-'}
            </Text>
          ),
        },
        ...headerArray.map((headerItem: any) => ({
          align: 'center',
          key: headerItem,
          title: headerItem,
          render: (item: any) => {
            const key = headerItem.replace(/[^a-zA-Z0-9]/g, '_');
            const arrow = () => {
              if (item[key]?.arrow === 'INCREASE') {
                return (
                  <Text style={{ color: '#ff4d4f' }} key={`${key}-increase`}>
                    <ArrowUpOutlined />
                  </Text>
                );
              }
              if (item[key]?.arrow === 'DECREASE') {
                return (
                  <Text
                    style={{ color: 'rgb(22, 119, 255)' }}
                    key={`${key}-decrease`}
                  >
                    <ArrowDownOutlined />
                  </Text>
                );
              }
              return '';
            };
            if (item[key]?.value) {
              if (item[key].status === 'HIGH') {
                return (
                  <div key={`${key}-high`}>
                    <Text
                      style={{ color: '#ff4d4f' }}
                      key={`${key}-value-high`}
                    >
                      {item[key].value}
                    </Text>
                    {arrow()}
                  </div>
                );
              }
              if (item[key].status === 'LOW') {
                return (
                  <div key={`${key}-low`}>
                    <Text
                      style={{ color: 'rgb(22, 119, 255)' }}
                      key={`${key}-value-low`}
                    >
                      {item[key].value}
                    </Text>
                    {arrow()}
                  </div>
                );
              }
              if (item[key].status === 'UNDEFINE') {
                return (
                  <div key={`${key}-undefined`}>
                    <Text
                      style={{ color: '#FF9800' }}
                      key={`${key}-value-undefined`}
                    >
                      <Tooltip
                        title={`Missing configure for ${item[key].code}`}
                      >
                        {item[key].value}
                      </Tooltip>
                    </Text>
                    {arrow()}
                  </div>
                );
              }
              return (
                <div key={`${key}-default`}>
                  <Text key={`${key}-value-default`}>{item[key].value} </Text>
                  {arrow()}
                </div>
              );
            }
            return <div key={`${key}-empty`}>-</div>;
          },
        })),
      ];
    }
    return [];
  }, [labSummaryData]);

  // Transform content lab summary data
  const transformLabSummaryData = useMemo(() => {
    if (labSummaryData && labSummaryData.content) {
      return {
        ...labSummaryData,
        content: labSummaryData.content.map((item: any) => {
          const labValues = item.labValueList.reduce(
            (acc: any, labValue: any) => {
              const codeKey = labValue.code.replace(/[^a-zA-Z0-9]/g, '_');
              acc[codeKey] = {
                code: labValue.code,
                value: labValue.value,
                status: labValue.status,
                arrow: labValue.arrow,
              };
              return acc;
            },
            {},
          );
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { labValueList, ...rest } = item;
          return {
            ...rest,
            ...labValues,
          };
        }),
      };
    }
    return {};
  }, [labSummaryData]);

  // Export data
  const exportData = (data: any[]): any[] => {
    return data?.map((item) => {
      const simplifiedItem: any = {
        id: item.id,
        rowIndex: item.rowIndex,
        highIndication: item.highIndication,
        lowIndication: item.lowIndication,
        resultDate: item.resultDate,
        labResultId: item.labResultId,
      };
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === 'object' && item[key]?.value !== undefined) {
          simplifiedItem[key] = item[key].value;
        }
      });
      return simplifiedItem;
    });
  };
  const exportedData = exportData(transformLabSummaryData.content);

  const isNullValue = watch('isNullValue') || false;
  const statusList = watch('statusList') || [];
  const fromDate =
    (watch('rangePicker') &&
      watch('rangePicker')[0].format(
        MOMENT_FORMAT.YEAR_MONTH_DAY_HORIZONTAL,
      )) ||
    '';
  const toDate =
    (watch('rangePicker') &&
      watch('rangePicker')[1].format(
        MOMENT_FORMAT.YEAR_MONTH_DAY_HORIZONTAL,
      )) ||
    '';

  const labSummaryQuery = {
    labResultId: labResultItemId,
    isNullValue: !isNullValue,
    statusList,
    fromDate,
    toDate,
    page: 0,
    pageSize: 100,
    sortBy: 'resultDate',
    orderBy: 'DESC',
  };

  const onSubmit = async () => {
    try {
      await dispatch(
        fetchLabSummaryData(
          labResultItemId,
          labSummaryQuery,
          controller.signal,
        ),
      );
    } catch (error: any) {
      console.error(
        'Error fetching lab results:',
        error.message || 'An unknown error occurred.',
      );
    }
  };

  const onReset = async () => {
    try {
      reset();
      await dispatch(
        fetchLabSummaryData(
          labResultItemId,
          {
            ...labSummaryQuery,
            statusList: [],
            fromDate: '',
            toDate: '',
          },
          controller.signal,
        ),
      );
    } catch (error: any) {
      console.error(
        'Error fetching lab results:',
        error.message || 'An unknown error occurred.',
      );
    }
  };

  // export to excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(exportedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    XLSX.writeFile(workbook, 'Lab_Summary.xlsx');
  };

  useEffect(() => {
    if (error) {
      message.error(
        intl.formatMessage({
          id: `${messages.error.id}`,
        }),
      );
    }
  }, [error]);

  useEffect(() => {
    dispatch(
      fetchLabSummaryData(labResultItemId, labSummaryQuery, controller.signal),
    );
    if (error) {
      message.error(
        intl.formatMessage({
          id: `${messages.error.id}`,
        }),
      );
    }
    return () => {
      dispatch(labSummaryCleanData());
    };
  }, []);

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: '20px' }}>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <span
                    onClick={() => navigate('/')}
                    style={{ cursor: 'pointer' }}
                  >
                    <HomeOutlined />
                  </span>
                ),
              },
              {
                title: (
                  <span
                    onClick={() => navigate('/lab-results')}
                    style={{ cursor: 'pointer' }}
                  >
                    <FormattedMessage {...messages.titleLabResultDetail} />
                  </span>
                ),
              },
              {
                title: (
                  <span>
                    <FormattedMessage {...messages.segmentListView} />
                  </span>
                ),
              },
            ]}
          />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Typography style={{ marginBottom: '10px' }}>
            <Text style={{ marginRight: '20px' }}>
              {intl.formatMessage({
                id: `${messages.tableTitleImportDate.id}`,
              })}
              :{' '}
              {moment(labResultItem.importDate).format(
                MOMENT_FORMAT.DAY_MONTH_YEAR,
              )}
            </Text>
            <Text>
              {intl.formatMessage({
                id: `${messages.tableTitleFileName.id}`,
              })}
              :{' '}
              {labResultItem.fileName &&
                labResultItem.fileName.replace('.csv', '')}
            </Text>
          </Typography>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Space>
              <Controller
                control={control}
                name="rangePicker"
                render={({ field }) => (
                  <RangePicker
                    {...field}
                    placeholder={[
                      `${intl.formatMessage({
                        id: `${messages.placeholderStartDate.id}`,
                      })}`,
                      `${intl.formatMessage({
                        id: `${messages.placeholderEndDate.id}`,
                      })}`,
                    ]}
                  />
                )}
              />
              <Controller
                control={control}
                name="statusList"
                render={({ field }) => (
                  <Select
                    {...field}
                    mode="multiple"
                    style={{
                      width: 175,
                    }}
                    allowClear
                    options={statusListOptions}
                    placeholder={intl.formatMessage({
                      id: `${messages.placeholderStatusList.id}`,
                    })}
                  />
                )}
              />
              <Controller
                control={control}
                name="isNullValue"
                render={({ field }) => (
                  <Checkbox {...field}>
                    <FormattedMessage {...messages.checkboxHideColumns} />
                  </Checkbox>
                )}
              />
              <Tooltip
                title={intl.formatMessage({
                  id: `${messages.tooltipSearch.id}`,
                })}
              >
                <Button
                  shape="circle"
                  icon={<SearchOutlined />}
                  onClick={handleSubmit(onSubmit)}
                />
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: `${messages.tooltipReset.id}`,
                })}
              >
                <Button
                  shape="circle"
                  icon={<UndoOutlined />}
                  onClick={onReset}
                />
              </Tooltip>
            </Space>
            <input type="submit" hidden />
          </form>
        </Col>
        <Col>
          <Button type="primary" onClick={exportToExcel}>
            <FormattedMessage {...messages.buttonExport} />
          </Button>
        </Col>
      </Row>
      <Section>
        <Table
          className={styles.customTable}
          columns={columns}
          dataSource={transformLabSummaryData.content}
          scroll={{ x: 'max-content', y: 105 * 5 }}
        />
      </Section>
      <Spin fullscreen spinning={loading} />
    </>
  );
};

export default LabSummary;
