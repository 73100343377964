import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from '../reducers/loginSlice';
import labResultReducer from '../reducers/labResultSlice';
import labSummaryReducer from '../reducers/labSummarySlice';
import labSummaryCompareReducer from '../reducers/labSummaryCompareSlice';
import labConfigureReducer from '../reducers/labConfigureSlice';
import labConfigureRangeReducer from '../reducers/labConfigureRangeSlice';
import healthCarePlanReducer from '../reducers/healthCarePlanSlice';

const rootReducer = combineReducers({
  login: loginReducer,
  labResult: labResultReducer,
  labSummary: labSummaryReducer,
  labSummaryCompare: labSummaryCompareReducer,
  labConfigure: labConfigureReducer,
  labConfigureRange: labConfigureRangeReducer,
  healthCarePlan: healthCarePlanReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
