import React from 'react';
import {
  LogoutOutlined,
  PlusOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import messages from '../locales/messages';

interface HeaderProps {
  setBasicAuth: (basicAuth: string) => void;
}

const NavBar = styled.div`
  text-align: center;
`;

const Header: React.FC<HeaderProps> = ({ setBasicAuth }) => {
  const intl = useIntl();
  const logOut = () => {
    sessionStorage.clear();
    setBasicAuth('');
  };

  return (
    <NavBar>
      <Tooltip
        title={intl.formatMessage({
          id: `${messages.titleLabResultDetail.id}`,
        })}
      >
        <Typography.Paragraph>
          <Link to="/lab-results" style={{ fontSize: '22px' }}>
            <UnorderedListOutlined />
          </Link>
        </Typography.Paragraph>
      </Tooltip>
      <Tooltip
        title={intl.formatMessage({
          id: `${messages.titleLabConfigures.id}`,
        })}
      >
        <Typography.Paragraph>
          <Link to="/lab-configures" style={{ fontSize: '22px' }}>
            <SettingOutlined />
          </Link>
        </Typography.Paragraph>
      </Tooltip>
      <Tooltip
        title={intl.formatMessage({
          id: `${messages.titleHealthcarePlanTemplate.id}`,
        })}
      >
        <Typography.Paragraph>
          <Link to="/health-care-plans" style={{ fontSize: '22px' }}>
            <PlusOutlined />
          </Link>
        </Typography.Paragraph>
      </Tooltip>
      <Tooltip
        title={intl.formatMessage({
          id: `${messages.tooltipLogout.id}`,
        })}
      >
        <Typography.Paragraph style={{ cursor: 'pointer' }} onClick={logOut}>
          <Link to="/">
            <LogoutOutlined style={{ fontSize: '22px' }} />
          </Link>
        </Typography.Paragraph>
      </Tooltip>
    </NavBar>
  );
};

export default Header;
