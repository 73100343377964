/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store/store';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/apiConfig';
import { axiosFormDataInstance, axiosInstance } from '../config/axiosConfig';

interface LabResultState {
  labResultData: any;
  labConfigureData: any;
  updateLabResultData: any;
  deleteLabResultData: any;
  importLabResultData: any;
  loading: boolean;
  error: string | null;
}

const initialState: LabResultState = {
  labResultData: null,
  labConfigureData: null,
  updateLabResultData: null,
  deleteLabResultData: null,
  importLabResultData: null,
  loading: false,
  error: null,
};

export const labResultSlice = createSlice({
  name: 'labResult',
  initialState,
  reducers: {
    labResultStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    labResultSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.labResultData = action.payload;
    },
    labResultFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateLabResultStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateLabResultSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updateLabResultData = action.payload;
    },
    updateLabResultFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateLabResultCleanData: (state) => {
      state.updateLabResultData = null;
      state.error = null;
    },
    deleteLabResultStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteLabResultSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.deleteLabResultData = action.payload;
    },
    deleteLabResultFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteLabResultCleanData: (state) => {
      state.deleteLabResultData = null;
      state.error = null;
    },
    importLabResultStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    importLabResultSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.importLabResultData = action.payload;
    },
    importLabResultFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    importLabResultCleanData: (state) => {
      state.importLabResultData = null;
      state.error = null;
    },
    labResultCleanData: (state) => {
      state.labResultData = null;
      state.updateLabResultData = null;
      state.deleteLabResultData = null;
      state.importLabResultData = null;
      state.error = null;
    },
  },
});

export const {
  labResultStart,
  labResultSuccess,
  labResultFailure,
  updateLabResultStart,
  updateLabResultSuccess,
  updateLabResultFailure,
  deleteLabResultStart,
  deleteLabResultSuccess,
  deleteLabResultFailure,
  importLabResultStart,
  importLabResultSuccess,
  importLabResultFailure,
  labResultCleanData,
} = labResultSlice.actions;

export const fetchLabResultData =
  (
    labResultQuery: {
      fileName: string;
      fromDate: string;
      toDate: string;
      page: number;
      pageSize: number;
      sortBy: string;
      orderBy: string;
    },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    const { fileName, fromDate, toDate, page, pageSize, sortBy, orderBy } =
      labResultQuery;
    dispatch(labResultStart());
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.FETCH_LAB_RESULT}?fileName=${fileName}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&orderBy=${orderBy}`,
        {
          signal,
        },
      );
      dispatch(labResultSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(labResultFailure(error.message || 'Something went wrong'));
      }
    }
  };

export const updateLabResult =
  (
    id: number | undefined,
    updateLabResultQuery: {
      fileName: string;
      importDate: string;
      labConfigureId: number | undefined;
    },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(updateLabResultStart());
    try {
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.UPDATE_LAB_RESULT}/${id}`,
        updateLabResultQuery,
        {
          signal,
        },
      );
      dispatch(updateLabResultSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          updateLabResultFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const deleteLabResult =
  (id: number | undefined, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(deleteLabResultStart());
    try {
      const response = await axiosInstance.delete(
        `${API_ENDPOINTS.DELETE_LAB_RESULT}/${id}`,
        {
          signal,
        },
      );
      dispatch(deleteLabResultSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          deleteLabResultFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const importLabResult =
  (id: number | undefined, formData: FormData, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(importLabResultStart());
    try {
      const response = await axiosFormDataInstance.post(
        `${API_ENDPOINTS.IMPORT_LAB_RESULT}?labConfigureId=${id}`,
        formData,
        {
          signal,
        },
      );
      dispatch(importLabResultSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          importLabResultFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export default labResultSlice.reducer;
