/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store/store';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/apiConfig';
import { axiosInstance } from '../config/axiosConfig';
import download from 'js-file-download';

interface LabConfigureRangeState {
  labConfigureRangeData: any;
  createLabConfigureRangeData: any;
  updateLabConfigureRangeData: any;
  deleteLabConfigureRangeData: any;
  exportLabConfigureRangeData: any;
  loading: boolean;
  error: string | null;
}

const initialState: LabConfigureRangeState = {
  labConfigureRangeData: null,
  createLabConfigureRangeData: null,
  updateLabConfigureRangeData: null,
  deleteLabConfigureRangeData: null,
  exportLabConfigureRangeData: null,
  loading: false,
  error: null,
};

export const labConfigureRangeSlice = createSlice({
  name: 'labConfigureRange',
  initialState,
  reducers: {
    labConfigureRangeStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    labConfigureRangeSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.labConfigureRangeData = action.payload;
    },
    labConfigureRangeFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    createLabConfigureRangeStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    createLabConfigureRangeSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.createLabConfigureRangeData = action.payload;
    },
    createLabConfigureRangeFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateLabConfigureRangeStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateLabConfigureRangeSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updateLabConfigureRangeData = action.payload;
    },
    updateLabConfigureRangeFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteLabConfigureRangeStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteLabConfigureRangeSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.deleteLabConfigureRangeData = action.payload;
    },
    deleteLabConfigureRangeFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    exportLabConfigureRangeStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    exportLabConfigureRangeSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.exportLabConfigureRangeData = action.payload;
    },
    exportLabConfigureRangeFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    labConfigureRangeCleanData: (state) => {
      state.labConfigureRangeData = null;
      state.createLabConfigureRangeData = null;
      state.updateLabConfigureRangeData = null;
      state.deleteLabConfigureRangeData = null;
      state.exportLabConfigureRangeData = null;
      state.error = null;
    },
  },
});

export const {
  labConfigureRangeStart,
  labConfigureRangeSuccess,
  labConfigureRangeFailure,
  createLabConfigureRangeStart,
  createLabConfigureRangeSuccess,
  createLabConfigureRangeFailure,
  updateLabConfigureRangeStart,
  updateLabConfigureRangeSuccess,
  updateLabConfigureRangeFailure,
  deleteLabConfigureRangeStart,
  deleteLabConfigureRangeSuccess,
  deleteLabConfigureRangeFailure,
  exportLabConfigureRangeStart,
  exportLabConfigureRangeSuccess,
  exportLabConfigureRangeFailure,
  labConfigureRangeCleanData,
} = labConfigureRangeSlice.actions;

export const fetchLabConfigureRangeData =
  (id: string | undefined, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(labConfigureRangeStart());
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.FETCH_LAB_CONFIGURE_RANGE}?labConfigureId=${id}`,
        {
          signal,
        },
      );
      dispatch(labConfigureRangeSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          labConfigureRangeFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const createLabConfigureRange =
  (
    createLabConfigureRangeQuery: {
      code: string;
      display: string;
      maxValue: number;
      minValue: number;
      unit: string;
      labConfigureId: string | undefined;
    },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(createLabConfigureRangeStart());
    try {
      const response = await axiosInstance.post(
        `${API_ENDPOINTS.UPDATE_LAB_CONFIGURE_RANGE}`,
        createLabConfigureRangeQuery,
        {
          signal,
        },
      );
      dispatch(createLabConfigureRangeSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          createLabConfigureRangeFailure(
            error.message || 'Something went wrong',
          ),
        );
      }
    }
  };

export const updateLabConfigureRange =
  (
    id: number,
    updateLabConfigureRangeQuery: {
      code: string;
      display: string;
      maxValue: number;
      minValue: number;
      unit: string;
      labConfigureId: string | undefined;
    },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(updateLabConfigureRangeStart());
    try {
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.UPDATE_LAB_CONFIGURE_RANGE}/${id}`,
        updateLabConfigureRangeQuery,
        {
          signal,
        },
      );
      dispatch(updateLabConfigureRangeSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          updateLabConfigureRangeFailure(
            error.message || 'Something went wrong',
          ),
        );
      }
    }
  };

export const deleteLabConfigureRange =
  (id: number | undefined, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(deleteLabConfigureRangeStart());
    try {
      const response = await axiosInstance.delete(
        `${API_ENDPOINTS.DELETE_LAB_CONFIGURE_RANGE}/${id}`,
        {
          signal,
        },
      );
      dispatch(deleteLabConfigureRangeSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          deleteLabConfigureRangeFailure(
            error.message || 'Something went wrong',
          ),
        );
      }
    }
  };

export const exportLabConfigureRange =
  (id: string | undefined, fileName: string, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(exportLabConfigureRangeStart());
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.EXPORT_LAB_CONFIGURE}?id=${id}`,
        {
          signal,
          responseType: 'blob',
        },
      );
      download(response.data, fileName);
      dispatch(exportLabConfigureRangeSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          exportLabConfigureRangeFailure(
            error.message || 'Something went wrong',
          ),
        );
      }
    }
  };

export default labConfigureRangeSlice.reducer;
