/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store/store';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/apiConfig';
import { axiosInstance } from '../config/axiosConfig';

interface LabSummaryCompareState {
  labSummaryCompareData: any;
  labSummaryDateData: any;
  updateLabSummaryHealthCarePlanData: any;
  loading: boolean;
  error: string | null;
}

const initialState: LabSummaryCompareState = {
  labSummaryCompareData: null,
  labSummaryDateData: null,
  updateLabSummaryHealthCarePlanData: null,
  loading: false,
  error: null,
};

export const labSummaryCompareSlice = createSlice({
  name: 'labSummaryCompare',
  initialState,
  reducers: {
    labSummaryCompareStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    labSummaryCompareSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.labSummaryCompareData = action.payload;
    },
    labSummaryCompareFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    labSummaryDateStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    labSummaryDateSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.labSummaryDateData = action.payload;
    },
    labSummaryDateFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateLabSummaryHealthCarePlanStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateLabSummaryHealthCarePlanSuccess: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.loading = false;
      state.updateLabSummaryHealthCarePlanData = action.payload;
    },
    updateLabSummaryHealthCarePlanFailure: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
    labSummaryCompareCleanData: (state) => {
      state.labSummaryCompareData = null;
      state.updateLabSummaryHealthCarePlanData = null;
      state.error = null;
    },
  },
});

export const {
  labSummaryCompareStart,
  labSummaryCompareSuccess,
  labSummaryCompareFailure,
  labSummaryDateStart,
  labSummaryDateSuccess,
  labSummaryDateFailure,
  updateLabSummaryHealthCarePlanStart,
  updateLabSummaryHealthCarePlanSuccess,
  updateLabSummaryHealthCarePlanFailure,
  labSummaryCompareCleanData,
} = labSummaryCompareSlice.actions;

export const fetchLabSummaryCompareData =
  (
    id: number,
    labSummaryCompareQuery: {
      statusList: [];
      fromDate: string;
      toDate: string;
    },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    const { statusList, fromDate, toDate } = labSummaryCompareQuery;
    dispatch(labSummaryCompareStart());
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.FETCH_LAB_SUMMARY_COMPARE}/${id}?statusList=${statusList}&fromDate=${fromDate}&toDate=${toDate}`,
        {
          signal,
        },
      );
      dispatch(labSummaryCompareSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          labSummaryCompareFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const fetchLabSummaryDateData =
  (id: number, signal: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(labSummaryDateStart());
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.FETCH_LAB_RESULT_DATE}/${id}`,
        {
          signal,
        },
      );
      dispatch(labSummaryDateSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          labSummaryDateFailure(error.message || 'Something went wrong'),
        );
      }
    }
  };

export const updateLabSummaryHealthCarePlan =
  (
    id: number,
    updateHealthCarePlanQuery: {
      data: string;
    },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(updateLabSummaryHealthCarePlanStart());
    try {
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.UPDATE_LAB_SUMMARY_HEALTH_CARE_PLAN}/${id}`,
        updateHealthCarePlanQuery,
        {
          signal,
        },
      );
      dispatch(updateLabSummaryHealthCarePlanSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(
          updateLabSummaryHealthCarePlanFailure(
            error.message || 'Something went wrong',
          ),
        );
      }
    }
  };

export default labSummaryCompareSlice.reducer;
