const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_ENDPOINTS = {
  SIGN_IN: `${API_BASE_URL}/sign-in`,

  FETCH_LAB_RESULT: `${API_BASE_URL}/lab-results`,
  FETCH_LAB_RESULT_DATE: `${API_BASE_URL}/lab-results/all-date`,
  UPDATE_LAB_RESULT: `${API_BASE_URL}/lab-results`,
  DELETE_LAB_RESULT: `${API_BASE_URL}/lab-results`,
  IMPORT_LAB_RESULT: `${API_BASE_URL}/lab-results/import`,

  FETCH_LAB_SUMMARY: `${API_BASE_URL}/lab-summary`,
  FETCH_LAB_SUMMARY_COMPARE: `${API_BASE_URL}/lab-summary/compare`,
  UPDATE_LAB_SUMMARY_HEALTH_CARE_PLAN: `${API_BASE_URL}/lab-summary/health-care-plan`,

  FETCH_LAB_CONFIGURE: `${API_BASE_URL}/lab-configures`,
  UPDATE_LAB_CONFIGURE: `${API_BASE_URL}/lab-configures`,
  DELETE_LAB_CONFIGURE: `${API_BASE_URL}/lab-configures`,
  IMPORT_LAB_CONFIGURE: `${API_BASE_URL}/lab-configures/import`,
  EXPORT_LAB_CONFIGURE: `${API_BASE_URL}/lab-configures/export`,

  FETCH_LAB_CONFIGURE_RANGE: `${API_BASE_URL}/lab-configure-ranges`,
  CREATE_LAB_CONFIGURE_RANGE: `${API_BASE_URL}/lab-configure-ranges`,
  UPDATE_LAB_CONFIGURE_RANGE: `${API_BASE_URL}/lab-configure-ranges`,
  DELETE_LAB_CONFIGURE_RANGE: `${API_BASE_URL}/lab-configure-ranges`,

  FETCH_HEALTH_CARE_PLAN: `${API_BASE_URL}/healthcare/plans`,
  CREATE_HEALTH_CARE_PLAN: `${API_BASE_URL}/healthcare/plans`,
  UPDATE_HEALTH_CARE_PLAN: `${API_BASE_URL}/healthcare/plans`,
  DELETE_HEALTH_CARE_PLAN: `${API_BASE_URL}/healthcare/plans`,
};
