/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Section } from '../styles/commonStyles';
import { AppDispatch, RootState } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MOMENT_FORMAT } from '../config/momentFormats';
import moment from 'moment';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  UndoOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
import {
  deleteLabResult,
  fetchLabResultData,
  importLabResult,
  labResultCleanData,
  updateLabResult,
} from '../reducers/labResultSlice';
import dayjs, { Dayjs } from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../locales/messages';
import { LAB_RESULT_STATUS } from '../contants/labResultStatus';
import { fetchLabConfigureData } from '../reducers/labConfigureSlice';
import { LabConfigure, LabResult } from '../common/interfaces';

const LabResults: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    labResultData,
    updateLabResultData,
    deleteLabResultData,
    importLabResultData,
    loading: labResultLoading,
    error: labResultError,
  } = useSelector((state: RootState) => state.labResult);
  const {
    labConfigureData,
    loading: labConfigureLoading,
    error: labConfigureError,
  } = useSelector((state: RootState) => state.labConfigure);

  const intl = useIntl();
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const controller = new AbortController();
  const { handleSubmit, control, watch, reset } = useForm();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalImportOpen, setIsModalImportOpen] = useState<boolean>(false);
  const [fileNameVal, setFileNameVal] = useState<string>('');
  const [importDate, setImportDate] = useState<string>('');

  const [labResultId, setLabResultId] = useState<number | undefined>(undefined);
  const [labConfigureId, setLabConfigureId] = useState<number | undefined>(
    undefined,
  );
  const [fileList, setFileList] = useState<any[]>([]);

  const fileName = watch('fileName') || '';
  const fromDate =
    (watch('rangePicker') &&
      watch('rangePicker')[0].format(
        MOMENT_FORMAT.YEAR_MONTH_DAY_HORIZONTAL,
      )) ||
    '';
  const toDate =
    (watch('rangePicker') &&
      watch('rangePicker')[1].format(
        MOMENT_FORMAT.YEAR_MONTH_DAY_HORIZONTAL,
      )) ||
    '';

  const labResultQuery = {
    fileName,
    fromDate,
    toDate,
    page: 0,
    pageSize: 100,
    sortBy: 'importDate',
    orderBy: 'DESC',
  };

  const updateLabResultQuery = {
    fileName: fileNameVal,
    importDate,
    labConfigureId,
  };

  // Update lab result
  const showModal = (item: LabResult) => {
    setFileNameVal(item.fileName);
    setImportDate(
      moment(item.importDate).format(MOMENT_FORMAT.YEAR_MONTH_DAY_HORIZONTAL),
    );
    setLabConfigureId(item.labConfigureId);
    setLabResultId(item.id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      updateLabResult(labResultId, updateLabResultQuery, controller.signal),
    );
    setIsModalOpen(false);
    setFileNameVal('');
    setImportDate('');
    setLabConfigureId(undefined);
    setLabResultId(undefined);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileNameVal('');
    setImportDate('');
    setLabConfigureId(undefined);
  };

  // Import lab result
  const showModalImport = () => {
    setIsModalImportOpen(true);
  };

  const handleOkImport = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file);
    });
    if (labConfigureId && !isEmpty(fileList)) {
      dispatch(importLabResult(labConfigureId, formData, controller.signal));
      setIsModalImportOpen(false);
    }
    setIsModalImportOpen(false);
    setLabConfigureId(undefined);
    setFileList([]);
  };

  const handleCancelImport = () => {
    setIsModalImportOpen(false);
    setLabConfigureId(undefined);
    setFileList([]);
  };

  // Lab result file props
  const fileProps = {
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const onChangeImportDate = (date: Dayjs | null) => {
    if (date) {
      setImportDate(date.format(MOMENT_FORMAT.YEAR_MONTH_DAY_HORIZONTAL));
    }
  };

  const onSubmit = async () => {
    try {
      await dispatch(fetchLabResultData(labResultQuery, controller.signal));
    } catch (error: any) {
      console.error(
        'Error fetching lab results:',
        error.message || 'An unknown error occurred.',
      );
    }
  };

  const onReset = async () => {
    try {
      reset();
      await dispatch(
        fetchLabResultData(
          {
            ...labResultQuery,
            fileName: '',
            fromDate: '',
            toDate: '',
          },
          controller.signal,
        ),
      );
    } catch (error: any) {
      console.error(
        'Error fetching lab results:',
        error.message || 'An unknown error occurred.',
      );
    }
  };

  const deleteResult = (id: number) => {
    dispatch(deleteLabResult(id, controller.signal));
  };

  // Redirect to lab summary
  const redirectToLabSummary = (item: LabResult) => {
    navigate('/lab-summary', {
      state: {
        labResultItem: item,
      },
    });
  };

  // Add index to lab result data
  const labResultDataMap = {
    ...labResultData,
    content:
      labResultData &&
      labResultData.content &&
      labResultData.content.map((i: LabResult, index: number) => ({
        ...i,
        index: index + 1,
      })),
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleNo.id}`,
      }),
      dataIndex: 'index',
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleImportDate.id}`,
      }),
      render: (item: LabResult) =>
        moment(item.importDate).format(MOMENT_FORMAT.YEAR_MONTH_DAY),
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleFileName.id}`,
      }),
      dataIndex: 'fileName',
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleStatus.id}`,
      }),
      render: (item: LabResult) =>
        intl.formatMessage({
          id: `${LAB_RESULT_STATUS[item.status].label}`,
        }),
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleAction.id}`,
      }),
      render: (item: LabResult) => (
        <Space>
          <EyeOutlined
            style={{ color: 'rgba(0, 0, 0, 0.88)' }}
            onClick={() => redirectToLabSummary(item)}
          />
          <EditOutlined onClick={() => showModal(item)} />
          <Modal
            title={intl.formatMessage({
              id: `${messages.modalTitleUpdateLabResult.id}`,
            })}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelText={intl.formatMessage({
              id: `${messages.buttonCancel.id}`,
            })}
            okText={intl.formatMessage({
              id: `${messages.buttonOk.id}`,
            })}
          >
            <Space>
              <Input
                placeholder={intl.formatMessage({
                  id: `${messages.tableTitleFileName.id}`,
                })}
                value={fileNameVal}
                onChange={(e) => setFileNameVal(e.target.value)}
              />
              <DatePicker
                value={dayjs(
                  moment(importDate).format(MOMENT_FORMAT.DAY_MONTH_YEAR),
                  MOMENT_FORMAT.DAY_MONTH_YEAR,
                )}
                format={MOMENT_FORMAT.DAY_MONTH_YEAR}
                onChange={(date: Dayjs | null) => onChangeImportDate(date)}
                placeholder={intl.formatMessage({
                  id: `${messages.tableTitleImportDate.id}`,
                })}
              />
              <Select
                onChange={(val) => setLabConfigureId(val)}
                value={labConfigureId}
                style={{
                  width: 200,
                }}
                allowClear
                options={
                  !isEmpty(labConfigureData) &&
                  labConfigureData.map((i: LabConfigure) => ({
                    ...i,
                    value: i.id,
                    label: i.name,
                  }))
                }
                placeholder={intl.formatMessage({
                  id: `${messages.placeholderConfigure.id}`,
                })}
              />
            </Space>
          </Modal>
          <Popconfirm
            title={intl.formatMessage({
              id: `${messages.popconfirmTitleDeleteLabResult.id}`,
            })}
            description={intl.formatMessage({
              id: `${messages.popconfirmDescriptionDeleteLabResult.id}`,
            })}
            onConfirm={() => deleteResult(item.id)}
            okText={intl.formatMessage({
              id: `${messages.popconfirmOkText.id}`,
            })}
            cancelText={intl.formatMessage({
              id: `${messages.popconfirmCancelText.id}`,
            })}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (
      updateLabResultData?.message === 'success' ||
      deleteLabResultData?.message === 'success' ||
      importLabResultData?.message === 'success'
    ) {
      dispatch(fetchLabResultData(labResultQuery, controller.signal));
      message.success(
        intl.formatMessage({
          id: `${messages.success.id}`,
        }),
      );
    }
    if (labResultError || labConfigureError) {
      message.error(
        intl.formatMessage({
          id: `${messages.error.id}`,
        }),
      );
    }
  }, [
    updateLabResultData,
    deleteLabResultData,
    importLabResultData,
    labResultError,
    labConfigureError,
  ]);

  useEffect(() => {
    dispatch(fetchLabResultData(labResultQuery, controller.signal));
    dispatch(fetchLabConfigureData('', controller.signal));
    return () => {
      dispatch(labResultCleanData());
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row justify="space-between" style={{ marginBottom: '20px' }}>
          <Col>
            <h2 style={{ margin: 0 }}>
              <FormattedMessage {...messages.titleLabResults} />
            </h2>
            <Typography>
              {intl.formatMessage(
                {
                  id: `${messages.titleTotalResults.id}`,
                },
                {
                  total:
                    labResultData &&
                    labResultData.content &&
                    labResultDataMap.content.length,
                },
              )}
            </Typography>
          </Col>
          <Col>
            <Controller
              control={control}
              name="fileName"
              render={({ field }) => (
                <Input
                  placeholder={intl.formatMessage({
                    id: `${messages.placeholderSearchByName.id}`,
                  })}
                  prefix={<SearchOutlined />}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <Space>
              <Controller
                control={control}
                name="rangePicker"
                render={({ field }) => (
                  <RangePicker
                    {...field}
                    format={MOMENT_FORMAT.DAY_MONTH_YEAR}
                    placeholder={[
                      `${intl.formatMessage({
                        id: `${messages.placeholderStartDate.id}`,
                      })}`,
                      `${intl.formatMessage({
                        id: `${messages.placeholderEndDate.id}`,
                      })}`,
                    ]}
                  />
                )}
              />
              <Tooltip
                title={intl.formatMessage({
                  id: `${messages.tooltipSearch.id}`,
                })}
              >
                <Button
                  shape="circle"
                  icon={<SearchOutlined />}
                  onClick={handleSubmit(onSubmit)}
                />
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: `${messages.tooltipReset.id}`,
                })}
              >
                <Button
                  shape="circle"
                  icon={<UndoOutlined />}
                  onClick={onReset}
                />
              </Tooltip>
            </Space>
          </Col>
          <Col>
            <Button type="primary" onClick={() => showModalImport()}>
              <FormattedMessage {...messages.buttonImport} />
            </Button>
            <Modal
              title={intl.formatMessage({
                id: `${messages.buttonImport.id}`,
              })}
              open={isModalImportOpen}
              onOk={handleOkImport}
              onCancel={handleCancelImport}
              cancelText={intl.formatMessage({
                id: `${messages.buttonCancel.id}`,
              })}
              okText={intl.formatMessage({
                id: `${messages.buttonOk.id}`,
              })}
              okButtonProps={{ disabled: !labConfigureId || isEmpty(fileList) }}
            >
              <Space>
                <Select
                  onChange={(val) => setLabConfigureId(val)}
                  value={labConfigureId}
                  style={{
                    width: 200,
                  }}
                  allowClear
                  options={
                    !isEmpty(labConfigureData) &&
                    labConfigureData
                      .filter((i: LabConfigure) => i.status === 'ACTIVE')
                      .map((i: LabConfigure) => ({
                        ...i,
                        value: i.id,
                        label: i.name,
                      }))
                  }
                  placeholder={intl.formatMessage({
                    id: `${messages.placeholderConfigure.id}`,
                  })}
                />
                <Upload {...fileProps}>
                  <Button icon={<UploadOutlined />}>
                    <FormattedMessage {...messages.buttonSelectFile} />
                  </Button>
                </Upload>
              </Space>
            </Modal>
          </Col>
        </Row>
        <input type="submit" hidden />
      </form>
      <Section>
        <Table columns={columns} dataSource={labResultDataMap.content} />
      </Section>
      <Spin fullscreen spinning={labResultLoading || labConfigureLoading} />
    </>
  );
};

export default LabResults;
