import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { fetchLoginData, cleanData } from '../reducers/loginSlice';
import { Button, Form, Input, message, Select, Spin, Typography } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import messages from '../locales/messages';
import { FormattedMessage, useIntl } from 'react-intl';

interface LoginProps {
  setLocale?: (locale: string) => void;
  setBasicAuth?: (basicAuth: string) => void;
}

const Login: React.FC<LoginProps> = ({ setLocale, setBasicAuth }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { data, loading, error } = useSelector(
    (state: RootState) => state.login,
  );
  const intl = useIntl();
  const controller = new AbortController();
  const { handleSubmit, control, watch } = useForm();

  const username = watch('username') || '';
  const password = watch('password') || '';
  const locale = watch('locale') || 'jp';

  const localeOptions = [
    {
      value: 'jp',
      label: (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/jp.svg`}
            width={15}
            alt="jp"
            style={{ paddingRight: '3px' }}
          />
          <FormattedMessage {...messages.languageJapanese} />
        </>
      ),
    },
    {
      value: 'en',
      label: (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/en.svg`}
            width={15}
            alt="en"
            style={{ paddingRight: '3px' }}
          />
          <FormattedMessage {...messages.languageEnglish} />
        </>
      ),
    },
  ];

  const loginQuery = {
    username,
    password,
  };

  const onSubmit = () => {
    dispatch(fetchLoginData(loginQuery, controller.signal));
  };

  useEffect(() => {
    setLocale && setLocale(locale);
  }, [locale]);

  useEffect(() => {
    if (data && data.message) {
      const basicAuth = `Basic ${btoa(`${username}:${password}`)}`;
      setBasicAuth && setBasicAuth(basicAuth);
    }
    if (error) {
      message.error(error);
    }
    return () => {
      dispatch(cleanData());
    };
  }, [data, error, dispatch, history]);

  return (
    <>
      <Typography.Paragraph style={{ margin: 'auto' }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '4px',
          }}
        >
          <Controller
            control={control}
            name="username"
            render={({ field }) => (
              <Form.Item>
                <Input
                  required
                  {...field}
                  prefix={<UserOutlined />}
                  placeholder={intl.formatMessage({
                    id: `${messages.placeholderUsername.id}`,
                  })}
                />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <Form.Item>
                <Input
                  required
                  {...field}
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder={intl.formatMessage({
                    id: `${messages.placeholderPassword.id}`,
                  })}
                />
              </Form.Item>
            )}
          />
          <Button block type="primary" htmlType="submit">
            <FormattedMessage {...messages.buttonLogin} />
          </Button>
          <Controller
            control={control}
            name="locale"
            render={({ field }) => (
              <Select
                {...field}
                style={{
                  width: 120,
                  position: 'absolute',
                  top: '15px',
                  right: '15px',
                }}
                defaultValue={'jp'}
                options={localeOptions}
              />
            )}
          />
        </form>
      </Typography.Paragraph>
      <Spin fullscreen spinning={loading} />
    </>
  );
};

export default Login;
