/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store/store';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/apiConfig';
import { axiosInstance } from '../config/axiosConfig';

interface LabSummaryState {
  labSummaryData: any;
  loading: boolean;
  error: string | null;
}

const initialState: LabSummaryState = {
  labSummaryData: null,
  loading: false,
  error: null,
};

export const labSummarySlice = createSlice({
  name: 'labSummary',
  initialState,
  reducers: {
    labSummaryStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    labSummarySuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.labSummaryData = action.payload;
    },
    labSummaryFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    labSummaryCleanData: (state) => {
      state.labSummaryData = null;
      state.error = null;
    },
  },
});

export const {
  labSummaryStart,
  labSummarySuccess,
  labSummaryFailure,
  labSummaryCleanData,
} = labSummarySlice.actions;

export const fetchLabSummaryData =
  (
    id: number,
    labSummaryQuery: {
      isNullValue: boolean;
      statusList: [];
      fromDate: string;
      toDate: string;
      page: number;
      pageSize: number;
      sortBy: string;
      orderBy: string;
    },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    const {
      isNullValue,
      statusList,
      fromDate,
      toDate,
      page,
      pageSize,
      sortBy,
      orderBy,
    } = labSummaryQuery;
    dispatch(labSummaryStart());
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.FETCH_LAB_SUMMARY}?labResultId=${id}&isNullValue=${isNullValue}&statusList=${statusList}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&orderBy=${orderBy}`,
        {
          signal,
        },
      );
      dispatch(labSummarySuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(labSummaryFailure(error.message || 'Something went wrong'));
      }
    }
  };

export default labSummarySlice.reducer;
