/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store/store';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/apiConfig';
import { axiosInstance } from '../config/axiosConfig';

interface LoginState {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: LoginState = {
  data: null,
  loading: false,
  error: null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    cleanData: (state) => {
      state.data = null;
      state.error = null;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, cleanData } =
  loginSlice.actions;

export const fetchLoginData =
  (
    loginQuery: { username: string; password: string },
    signal: AbortSignal,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(loginStart());
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.SIGN_IN,
        loginQuery,
        { signal },
      );
      dispatch(loginSuccess(response.data));
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log('Request was canceled', error.message);
      } else {
        dispatch(loginFailure(error.message || 'Something went wrong'));
      }
    }
  };

export default loginSlice.reducer;
