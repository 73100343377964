/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../locales/messages';
import {
  Button,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import { isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { Section } from '../styles/commonStyles';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import {
  createLabConfigureRange,
  deleteLabConfigureRange,
  exportLabConfigureRange,
  fetchLabConfigureRangeData,
  labConfigureRangeCleanData,
  updateLabConfigureRange,
} from '../reducers/labConfigureRangeSlice';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

interface LocationState {
  labConfigureId?: string; // Hoặc kiểu dữ liệu khác nếu không phải là string
}

const LabConfigureRange: React.FC = () => {
  const {
    labConfigureRangeData,
    createLabConfigureRangeData,
    updateLabConfigureRangeData,
    deleteLabConfigureRangeData,
    loading,
    error,
  } = useSelector((state: RootState) => state.labConfigureRange);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation() as { state: LocationState };
  const labConfigureId = location.state?.labConfigureId;
  const controller = new AbortController();
  const intl = useIntl();
  const { handleSubmit, control, watch, reset, setValue } = useForm();
  const [isModalLabConfigureRange, setIsModalLabConfigureRange] =
    useState(false);
  const [labConfigureRangeId, setLabConfigureRangeId] = useState();

  const code = watch('code') || '';
  const display = watch('display') || '';
  const maxValue = watch('maxValue') || '';
  const minValue = watch('minValue') || '';
  const unit = watch('unit') || '';

  const labConfigureRangeQuery = {
    code,
    display,
    maxValue,
    minValue,
    unit,
    labConfigureId,
  };

  const deleteResult = (id: number) => {
    dispatch(deleteLabConfigureRange(id, controller.signal));
  };

  const cancelLabConfigureRange = () => {
    setIsModalLabConfigureRange(false);
    reset({
      code: '',
      display: '',
      maxValue: '',
      minValue: '',
      unit: '',
    });
  };

  const showModalLabConfigureRange = (item: any) => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
      setLabConfigureRangeId(item.id);
    } else {
      setLabConfigureRangeId(undefined);
    }
    setIsModalLabConfigureRange(true);
  };

  const onSubmit = () => {
    if (labConfigureRangeId) {
      dispatch(
        updateLabConfigureRange(
          labConfigureRangeId,
          labConfigureRangeQuery,
          controller.signal,
        ),
      );
    } else {
      dispatch(
        createLabConfigureRange(labConfigureRangeQuery, controller.signal),
      );
    }
    setIsModalLabConfigureRange(false);
    reset({
      code: '',
      display: '',
      maxValue: '',
      minValue: '',
      unit: '',
    });
  };

  const standardRange = (item: any) => {
    if (item.minValue && !item.maxValue) {
      return `≥ ${item.minValue}`;
    }
    if (!item.minValue && item.maxValue) {
      return `≤ ${item.maxValue}`;
    }
    if (item.minValue && item.maxValue) {
      return `${item.minValue}-${item.maxValue}`;
    }
    return '-';
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleIndexName.id}`,
      }),
      dataIndex: 'code',
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleDisplay.id}`,
      }),
      dataIndex: 'display',
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleStandardRange.id}`,
      }),
      render: (item: any) => <span>{standardRange(item)}</span>,
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleUnit.id}`,
      }),
      dataIndex: 'unit',
    },
    {
      title: intl.formatMessage({
        id: `${messages.tableTitleAction.id}`,
      }),
      render: (item: any) => (
        <Space>
          <EditOutlined onClick={() => showModalLabConfigureRange(item)} />
          <Popconfirm
            title={intl.formatMessage({
              id: `${messages.popconfirmTitleDeleteConfigureRange.id}`,
            })}
            description={intl.formatMessage({
              id: `${messages.popconfirmDescriptionDeleteConfigureRange.id}`,
            })}
            onConfirm={() => deleteResult(item.id)}
            okText={intl.formatMessage({
              id: `${messages.popconfirmOkText.id}`,
            })}
            cancelText={intl.formatMessage({
              id: `${messages.popconfirmCancelText.id}`,
            })}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (
      createLabConfigureRangeData?.message === 'success' ||
      updateLabConfigureRangeData?.message === 'success' ||
      deleteLabConfigureRangeData?.message === 'success'
    ) {
      dispatch(fetchLabConfigureRangeData(labConfigureId, controller.signal));
      message.success(
        intl.formatMessage({
          id: `${messages.success.id}`,
        }),
      );
    }
    if (error) {
      message.error(
        intl.formatMessage({
          id: `${messages.error.id}`,
        }),
      );
    }
  }, [
    createLabConfigureRangeData,
    updateLabConfigureRangeData,
    deleteLabConfigureRangeData,
    error,
  ]);

  useEffect(() => {
    dispatch(fetchLabConfigureRangeData(labConfigureId, controller.signal));
    return () => {
      dispatch(labConfigureRangeCleanData());
    };
  }, []);

  return (
    <>
      <h2 style={{ margin: 0 }}>
        <FormattedMessage {...messages.titleLabConfigureRange} />
      </h2>
      <Typography style={{ marginBottom: '20px' }}>
        {intl.formatMessage(
          {
            id: `${messages.titleTotalResults.id}`,
          },
          {
            total:
              !isEmpty(labConfigureRangeData) && labConfigureRangeData.length,
          },
        )}
      </Typography>
      <Row justify="end">
        <Space>
          <Button type="primary" onClick={() => showModalLabConfigureRange('')}>
            <FormattedMessage {...messages.buttonAdd} />
          </Button>
          <Modal
            title={intl.formatMessage({
              id: `${
                labConfigureRangeId
                  ? messages.modalTitleUpdateConfigureRange.id
                  : messages.modalTitleCreateConfigureRange.id
              }`,
            })}
            open={isModalLabConfigureRange}
            onCancel={cancelLabConfigureRange}
            footer={null}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="code"
                render={({ field }) => (
                  <Typography.Paragraph>
                    <Typography.Text>
                      <FormattedMessage {...messages.formLabelCode} />
                    </Typography.Text>
                    <Input
                      required
                      {...field}
                      placeholder={intl.formatMessage({
                        id: `${messages.formLabelCode.id}`,
                      })}
                    />
                  </Typography.Paragraph>
                )}
              />
              <Controller
                control={control}
                name="display"
                render={({ field }) => (
                  <Typography.Paragraph>
                    <Typography.Text>
                      <FormattedMessage {...messages.formLabelDisplay} />
                    </Typography.Text>
                    <Input
                      required
                      {...field}
                      placeholder={intl.formatMessage({
                        id: `${messages.formLabelDisplay.id}`,
                      })}
                    />
                  </Typography.Paragraph>
                )}
              />
              <Controller
                control={control}
                name="minValue"
                render={({ field }) => (
                  <Typography.Paragraph>
                    <Typography.Text>
                      <FormattedMessage {...messages.formLabelMinValue} />
                    </Typography.Text>
                    <InputNumber
                      style={{ width: '100%' }}
                      required
                      {...field}
                      placeholder={intl.formatMessage({
                        id: `${messages.formLabelMinValue.id}`,
                      })}
                    />
                  </Typography.Paragraph>
                )}
              />
              <Controller
                control={control}
                name="maxValue"
                render={({ field }) => (
                  <Typography.Paragraph>
                    <Typography.Text>
                      <FormattedMessage {...messages.formLabelMaxValue} />
                    </Typography.Text>
                    <InputNumber
                      style={{ width: '100%' }}
                      required
                      {...field}
                      placeholder={intl.formatMessage({
                        id: `${messages.formLabelMaxValue.id}`,
                      })}
                    />
                  </Typography.Paragraph>
                )}
              />
              <Controller
                control={control}
                name="unit"
                render={({ field }) => (
                  <Typography.Paragraph>
                    <Typography.Text>
                      <FormattedMessage {...messages.formLabelUnit} />
                    </Typography.Text>
                    <Input
                      required
                      {...field}
                      placeholder={intl.formatMessage({
                        id: `${messages.formLabelUnit.id}`,
                      })}
                    />
                  </Typography.Paragraph>
                )}
              />
              <Row justify="end">
                <Space>
                  <Button htmlType="button" onClick={cancelLabConfigureRange}>
                    <FormattedMessage {...messages.buttonCancel} />
                  </Button>
                  <Button type="primary" htmlType="submit">
                    <FormattedMessage {...messages.buttonSubmit} />
                  </Button>
                </Space>
              </Row>
            </form>
          </Modal>
          <Button
            type="primary"
            onClick={() =>
              dispatch(
                exportLabConfigureRange(
                  labConfigureId,
                  'Lab_configure_range.csv',
                  controller.signal,
                ),
              )
            }
          >
            <FormattedMessage {...messages.buttonExport} />
          </Button>
        </Space>
      </Row>
      <Section>
        <Table
          columns={columns}
          dataSource={
            (!isEmpty(labConfigureRangeData) && labConfigureRangeData) || []
          }
        />
      </Section>
      <Spin fullscreen spinning={loading} />
    </>
  );
};

export default LabConfigureRange;
