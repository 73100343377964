/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../locales/messages';
import moment from 'moment';
import { MOMENT_FORMAT } from '../config/momentFormats';
import { HomeOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { Section } from '../styles/commonStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store/store';
import { RootState } from '../store/rootReducer';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { isEmpty } from 'lodash';
import {
  fetchLabSummaryCompareData,
  fetchLabSummaryDateData,
  labSummaryCompareCleanData,
  updateLabSummaryHealthCarePlan,
} from '../reducers/labSummaryCompareSlice';
import { fetchHealthCarePlanData } from '../reducers/healthCarePlanSlice';
import { createStyles } from 'antd-style';

const useStyle = createStyles(({ css }) => {
  return {
    customTable: css`
      .ant-table-thead > tr > th {
        background-color: #58658c;
        color: #fff;
      }
      .ant-table-thead > tr > th:nth-child(4),
      .ant-table-thead > tr > th:nth-child(5) {
        background-color: #9e9e9e;
      }
    `,
  };
});

interface LabResultItem {
  id: number;
  importDate: string;
  fileName: string;
}

interface LabSummaryItem {
  id: number;
  resultDate: string;
}

const LabSummaryCompare: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    labSummaryCompareData,
    labSummaryDateData,
    updateLabSummaryHealthCarePlanData,
    loading,
    error,
  } = useSelector((state: RootState) => state.labSummaryCompare);

  const { healthCarePlanData } = useSelector(
    (state: RootState) => state.healthCarePlan,
  );
  const { styles } = useStyle();
  const location = useLocation();
  const labResultItem = location.state?.labResultItem as LabResultItem;
  const labSummaryItem = location.state?.labSummaryItem as LabSummaryItem;
  const labSummaryId = labSummaryItem?.id;
  const intl = useIntl();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const contentRef = useRef<HTMLDivElement | null>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const { Text } = Typography;
  const controller = new AbortController();
  const { handleSubmit, control, watch, reset } = useForm();
  const [healthCarePlan, setHealthCarePlan] = useState('');
  const [selectedDates, setSelectedDates] = useState({
    fromDate: '',
    toDate: '',
  });
  const [dateValueId, setDateValueId] = useState<number>(labSummaryId);
  const currentItem: any = useMemo(() => {
    if (
      labSummaryCompareData &&
      !isEmpty(labSummaryCompareData.labSummaryList)
    ) {
      return labSummaryCompareData.labSummaryList.find(
        (i: any) => i.id === dateValueId && i.status !== 'UNDEFINE',
      );
    }
  }, [labSummaryCompareData]);

  const statusListOptions = [
    {
      value: 'NORMAL',
      label: intl.formatMessage({
        id: `${messages.selectNormal.id}`,
      }),
    },
    {
      value: 'HIGH',
      label: intl.formatMessage({
        id: `${messages.selectHigh.id}`,
      }),
    },
    {
      value: 'LOW',
      label: intl.formatMessage({
        id: `${messages.selectLow.id}`,
      }),
    },
  ];

  // Transform content lab summary compare data
  const transformLabSummaryCompareData = useMemo(() => {
    let header: any[] = [];
    let content: any[] = [];
    const standardRange = (item: any) => {
      if (item.minValue && !item.maxValue) {
        return `≥ ${item.minValue}`;
      }
      if (!item.minValue && item.maxValue) {
        return `≤ ${item.maxValue}`;
      }
      if (item.minValue && item.maxValue) {
        return `${item.minValue} - ${item.maxValue}`;
      }
      return '-';
    };
    if (
      labSummaryCompareData &&
      !isEmpty(labSummaryCompareData.labSummaryList) &&
      !isEmpty(labSummaryCompareData.configure)
    ) {
      header = [
        {
          title: intl.formatMessage({
            id: `${messages.tableTitleIndexName.id}`,
          }),
          render: (item: any) => item?.code || '-',
        },
        {
          title: intl.formatMessage({
            id: `${messages.tableTitleStandardRange.id}`,
          }),
          render: (item: any) => <Text>{standardRange(item)}</Text>,
        },
        ...labSummaryCompareData.labSummaryList.map((summary: any) => ({
          key: summary.id,
          title: moment(summary.resultDate.replace(/-/g, '/')).format(
            MOMENT_FORMAT.YEAR_MONTH_DAY,
          ),
          render: (item: any) => {
            const key = `_${summary.resultDate.replace(/-/g, '_')}`;
            const dateKeys = Object.keys(item).filter((key) =>
              key.startsWith('_'),
            );
            dateKeys.sort(
              (a, b) =>
                new Date(b.slice(1)).getTime() - new Date(a.slice(1)).getTime(),
            );
            const firstDateKey = dateKeys.at(0);
            const arrow = () => {
              if (key === firstDateKey) {
                if (item[key]?.arrow === 'INCREASE') {
                  return (
                    <Text style={{ color: '#ff4d4f' }} key={`${key}-increase`}>
                      <ArrowUpOutlined />
                    </Text>
                  );
                }
                if (item[key]?.arrow === 'DECREASE') {
                  return (
                    <Text
                      style={{ color: 'rgb(22, 119, 255)' }}
                      key={`${key}-decrease`}
                    >
                      <ArrowDownOutlined />
                    </Text>
                  );
                }
              }
              return '';
            };
            if (item[key]?.value) {
              if (key === firstDateKey) {
                if (item[key].status === 'HIGH') {
                  return (
                    <div key={`${key}-high`}>
                      <Text
                        style={{ color: '#ff4d4f' }}
                        key={`${key}-value-high`}
                      >
                        {item[key].value}
                      </Text>
                      {arrow()}
                    </div>
                  );
                }
                if (item[key].status === 'LOW') {
                  return (
                    <div key={`${key}-low`}>
                      <Text
                        style={{ color: 'rgb(22, 119, 255)' }}
                        key={`${key}-value-low`}
                      >
                        {item[key].value}
                      </Text>
                      {arrow()}
                    </div>
                  );
                }
                if (item[key].status === 'UNDEFINE') {
                  return (
                    <div key={`${key}-undefined`}>
                      <Text
                        style={{ color: '#FF9800' }}
                        key={`${key}-value-undefined`}
                      >
                        <Tooltip
                          title={`Missing configure for ${item[key].code}`}
                        >
                          {item[key].value}
                        </Tooltip>
                      </Text>
                      {arrow()}
                    </div>
                  );
                }
                return (
                  <div key={`${key}-default`}>
                    <Text key={`${key}-value-default`}>{item[key].value} </Text>
                    {arrow()}
                  </div>
                );
              }
              return (
                <div key={`${key}-default`}>
                  <Text
                    style={{ color: '#9E9E9E' }}
                    key={`${key}-value-default`}
                  >
                    {item[key].value}{' '}
                  </Text>
                  {arrow()}
                </div>
              );
            }
            return (
              <div
                style={{ color: key === firstDateKey ? '' : '#9E9E9E' }}
                key={`${key}-empty`}
              >
                -
              </div>
            );
          },
        })),
      ];
      // Tạo một map từ configureData để tra cứu nhanh
      const configureMap = new Map<string, any>();
      labSummaryCompareData.configure.forEach((item: any) => {
        configureMap.set(item.code, item);
      });
      // Tạo đối tượng để lưu trữ các giá trị theo từng `code`
      const contentMap = new Map<
        string,
        { code: string; [date: string]: any }
      >();
      labSummaryCompareData.labSummaryList.forEach((summary: any) => {
        summary.labValueList.forEach((labValue: any) => {
          const dateKey = `_${summary.resultDate.replace(/-/g, '_')}`;
          // Kiểm tra nếu code chưa có trong contentMap, thêm mới entry
          if (!contentMap.has(labValue.code)) {
            const entry: { code: string; [key: string]: any } = {
              code: labValue.code,
            };
            // Kiểm tra nếu code có trong configureMap và thêm minValue, maxValue nếu có
            const configureItem = configureMap.get(labValue.code);
            if (configureItem) {
              entry.minValue = configureItem.minValue;
              entry.maxValue = configureItem.maxValue;
            }
            contentMap.set(labValue.code, entry);
          }
          // Thêm giá trị ngày vào entry đã tồn tại trong contentMap
          const entry = contentMap.get(labValue.code);
          if (entry) {
            entry[dateKey] = {
              code: labValue.code,
              value: labValue.value,
              status: labValue.status,
              arrow: labValue.arrow,
            };
          }
        });
      });
      // Chuyển đổi map thành mảng kết quả
      content = Array.from(contentMap.values());
    }
    return { header, content };
  }, [labSummaryCompareData]);

  // Transform content health care plan data
  const transformHealthCarePlanData = useMemo(() => {
    if (!isEmpty(healthCarePlanData)) {
      return healthCarePlanData.map((i: any) => ({
        value: i.id,
        label: i.name,
        detail: i.detail,
      }));
    }
    return [];
  }, [healthCarePlanData]);

  const handleChangeHealthCarePlan = (detail: any) => {
    setHealthCarePlan(detail);
  };

  const statusList = watch('statusList') || [];

  const labSummaryCompareQuery = {
    statusList,
    fromDate: selectedDates.fromDate,
    toDate: selectedDates.toDate,
  };

  const selectDatesFunc = useCallback(
    (id: number) => {
      if (!isEmpty(labSummaryDateData)) {
        const index = labSummaryDateData.findIndex(
          (item: any) => item.id === id,
        );
        if (index !== -1) {
          setSelectedDates({
            fromDate: labSummaryDateData[index + 2]
              ? labSummaryDateData[index + 2]?.resultDate
              : labSummaryDateData[index + 1]
                ? labSummaryDateData[index + 1]?.resultDate
                : '',
            toDate: labSummaryDateData[index]?.resultDate,
          });
        }
      }
    },
    [labSummaryDateData],
  );

  const handleDateChange = (id: number) => {
    selectDatesFunc(id), setDateValueId(id);
  };

  const onSubmit = async () => {
    try {
      await dispatch(
        fetchLabSummaryCompareData(
          labSummaryId,
          labSummaryCompareQuery,
          controller.signal,
        ),
      );
    } catch (error: any) {
      console.error(
        'Error fetching lab results:',
        error.message || 'An unknown error occurred.',
      );
    }
  };

  const onReset = useCallback(() => {
    reset({
      statusList: [],
    });
    setDateValueId(labSummaryId);
    if (labSummaryId && !isEmpty(labSummaryDateData)) {
      selectDatesFunc(labSummaryId);
    }
  }, [labSummaryId, labSummaryDateData]);

  const onUpdateHealthCarePlan = () => {
    if (healthCarePlan) {
      dispatch(
        updateLabSummaryHealthCarePlan(
          dateValueId,
          {
            data: healthCarePlan,
          },
          controller.signal,
        ),
      );
    }
  };

  useEffect(() => {
    if (labSummaryId && !isEmpty(labSummaryDateData)) {
      selectDatesFunc(labSummaryId);
    }
  }, [labSummaryId, labSummaryDateData]);

  useEffect(() => {
    if (selectedDates.fromDate || selectedDates.toDate) {
      dispatch(
        fetchLabSummaryCompareData(
          dateValueId || labSummaryId,
          labSummaryCompareQuery,
          controller.signal,
        ),
      );
    }
  }, [selectedDates.fromDate, selectedDates.toDate]);

  useEffect(() => {
    setHealthCarePlan(currentItem?.healthCarePlan);
  }, [currentItem]);

  useEffect(() => {
    if (updateLabSummaryHealthCarePlanData?.message === 'success') {
      message.success(
        intl.formatMessage({
          id: `${messages.success.id}`,
        }),
      );
    }
    if (error) {
      message.error(
        intl.formatMessage({
          id: `${messages.error.id}`,
        }),
      );
    }
  }, [updateLabSummaryHealthCarePlanData, error]);

  useEffect(() => {
    dispatch(fetchLabSummaryDateData(labResultItem?.id, controller.signal));
    dispatch(fetchHealthCarePlanData(controller.signal));
    return () => {
      dispatch(labSummaryCompareCleanData());
    };
  }, []);

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: '20px' }}>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <span
                    onClick={() => navigate('/')}
                    style={{ cursor: 'pointer' }}
                  >
                    <HomeOutlined />
                  </span>
                ),
              },
              {
                title: (
                  <span
                    onClick={() => navigate('/lab-results')}
                    style={{ cursor: 'pointer' }}
                  >
                    <FormattedMessage {...messages.titleLabResultDetail} />
                  </span>
                ),
              },
              {
                title: (
                  <span>
                    <FormattedMessage {...messages.segmentSingleView} />
                  </span>
                ),
              },
            ]}
          />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Typography style={{ marginBottom: '10px' }}>
            <Text style={{ marginRight: '20px' }}>
              {intl.formatMessage({
                id: `${messages.tableTitleImportDate.id}`,
              })}
              :{' '}
              {moment(labResultItem.importDate).format(
                MOMENT_FORMAT.DAY_MONTH_YEAR,
              )}
            </Text>
            <Text>
              {intl.formatMessage({
                id: `${messages.tableTitleFileName.id}`,
              })}
              :{' '}
              {labResultItem.fileName &&
                labResultItem.fileName.replace('.csv', '')}
            </Text>
          </Typography>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Space>
              <Controller
                control={control}
                name="rangePicker"
                render={({ field }) => (
                  <Select
                    {...field}
                    style={{
                      width: 150,
                    }}
                    value={dateValueId}
                    options={labSummaryDateData?.map((i: any) => {
                      return {
                        value: i.id,
                        label: i.resultDate,
                      };
                    })}
                    onChange={handleDateChange}
                    placeholder={intl.formatMessage({
                      id: `${messages.titleDate.id}`,
                    })}
                  />
                )}
              />
              <Controller
                control={control}
                name="statusList"
                render={({ field }) => (
                  <Select
                    {...field}
                    mode="multiple"
                    style={{
                      width: 175,
                    }}
                    allowClear
                    options={statusListOptions}
                    placeholder={intl.formatMessage({
                      id: `${messages.placeholderStatusList.id}`,
                    })}
                  />
                )}
              />
              <Tooltip
                title={intl.formatMessage({
                  id: `${messages.tooltipSearch.id}`,
                })}
              >
                <Button
                  shape="circle"
                  icon={<SearchOutlined />}
                  onClick={handleSubmit(onSubmit)}
                />
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: `${messages.tooltipReset.id}`,
                })}
              >
                <Button
                  shape="circle"
                  icon={<UndoOutlined />}
                  onClick={onReset}
                />
              </Tooltip>
            </Space>
            <input type="submit" hidden />
          </form>
        </Col>
        <Col>
          <Space>
            <Button type="primary" onClick={() => reactToPrintFn()}>
              <FormattedMessage {...messages.buttonPrint} />
            </Button>
          </Space>
        </Col>
      </Row>
      <Section>
        <Row gutter={16}>
          <Col span={13}>
            <div ref={contentRef}>
              <Table
                className={styles.customTable}
                columns={transformLabSummaryCompareData.header}
                dataSource={transformLabSummaryCompareData.content}
                scroll={{
                  x: 'max-content',
                }}
              />
            </div>
          </Col>
          <Col span={11}>
            <h3 style={{ margin: 0 }}>
              <FormattedMessage {...messages.titleSummary} />:
            </h3>
            <Typography>
              <FormattedMessage {...messages.labelHighIndex} />:{' '}
              {currentItem && currentItem.highIndication}
            </Typography>
            <Typography>
              <FormattedMessage {...messages.labelLowIndex} />:{' '}
              {currentItem && currentItem.lowIndication}
            </Typography>
            <Typography style={{ margin: '10px 0' }}>
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.17em',
                  marginRight: '10px',
                }}
              >
                <FormattedMessage {...messages.titleHealthCarePlan} />:
              </Text>
              <Select
                onChange={(val, item: any) =>
                  handleChangeHealthCarePlan(item?.detail)
                }
                allowClear
                options={transformHealthCarePlanData}
                placeholder={intl.formatMessage({
                  id: `${messages.placeholderHealthCarePlan.id}`,
                })}
              />
            </Typography>
            <TextArea
              rows={4}
              style={{ width: '100%' }}
              value={healthCarePlan}
              onChange={(e) => setHealthCarePlan(e.target.value)}
            />
            <Typography style={{ textAlign: 'right', marginTop: '10px' }}>
              <Button
                type="primary"
                disabled={!healthCarePlan}
                onClick={onUpdateHealthCarePlan}
              >
                <FormattedMessage {...messages.buttonUpdate} />
              </Button>
            </Typography>
          </Col>
        </Row>
      </Section>
      <Spin fullscreen spinning={loading} />
    </>
  );
};

export default LabSummaryCompare;
