import messages from '../locales/messages';

interface LabResultStatus {
  value: string;
  label: string;
}

export const LAB_RESULT_STATUS: Record<string, LabResultStatus> = {
  NEW: {
    value: 'NEW',
    label: messages.tableStatusNew.id,
  },
  PROCESSING: {
    value: 'PROCESSING',
    label: messages.tableStatusProcessing.id,
  },
  DONE: {
    value: 'DONE',
    label: messages.tableStatusDone.id,
  },
  ERROR: {
    value: 'ERROR',
    label: messages.tableStatusError.id,
  },
};
